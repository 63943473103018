import axios from 'axios'
import * as THREE from 'three'
import {MathUtils} from 'three'
import {recursiveUpdate} from "../recursiveUpdate";
import {GridExtension} from "./GridExtension";
import {TileMap} from "./tile_map";

export class BuildingManager extends GridExtension {
	buildingsModels = {}

	//Buildings active on the scene
	activeBuildings = {};

	//Cached collision meshes for models
	colliderModelMeshes = {};

	//If in local mode, proxy requests to local http cache
	useLocalLoad = true


	constructor(gridController, modelManager, animationManager, collisionHandler, interactionHandler, scene) {
		super(gridController, modelManager, animationManager, collisionHandler, interactionHandler, scene)
	}

	setLocalLoad(loadLocal) {
		console.log("Building manager, localLoad: ", loadLocal)
		this.useLocalLoad = loadLocal
	}

	load() {
		axios.get('https://app.alphabatem.com/models/buildings').then(({data}) => {
			for (let i = 0; i < data.length; i++) {
				this.buildingsModels[data[i].id] = data[i]
				let uri = `https://app.alphabatem.com${data[i].model_src}`
				if (data[i].model_src.indexOf("https://") > -1)
					uri = data[i].model_src

				this.loadModel(data[i].id, uri)
			}
		})
	}

	async loadFromShadow() {
		const driveID = "CiMbMcfe4aeBmtCb9gbwkh7aF5ACiqBf5GsC46XeVxgP" //Manifest location

		// const {data} = await axios.get('https://app.alphabatem.com/models/buildings')
		const {data} = await axios.post('https://shadow-storage.genesysgo.net/list-objects', {storageAccount: driveID}) //TODO Move to env
		for (let i = 0; i < data.keys.length; i++) {
			const _ = this._loadBuildingFromShadow(driveID, data.keys[i])
		}
	}

	async _loadBuildingFromShadow(driveID, fileName) {
		let uri = `https://shdw-drive.genesysgo.net/${driveID}/${fileName}`
		if (this.useLocalLoad)
			uri = this.modelManager.toLocalUrl(uri)

		const {data} = await axios.get(uri)
		this.buildingsModels[data.id] = data
		return this.loadModel(data.id, data.model_src)
	}

	loadModel(i, url) {
		const key = `building-${i}`
		return this.modelManager.addModel(key, url, (model) => {
			const scale = 1;
			model.scale.set(scale, scale, scale)
			model.userData.tilesX = this.buildingsModels[i].size_x || 0
			model.userData.tilesZ = this.buildingsModels[i].size_z || 0
			model.userData.tilesRequired = this.buildingsModels[i].tiles_required
			model.castShadow = true
			model.receiveShadow = true


			recursiveUpdate(model)
			this.buildingsModels[i].model = model
			// this.colliderModelMeshes[i] = this.collisionHandler.buildCollider(model.clone())
			this.colliderModelMeshes[i] = this.collisionHandler.buildColliderSimple(model.clone())
		})
	}

	onGridChange(x, z) {
		this.pullBuildings(x, z)
	}

	pullBuildings(x, z) {
		const r = this.gridController.getDrawRadius()
		axios.get(`https://app.alphabatem.com/surrounding/buildings/${x}/${z}/radius/${r}`).then(({data}) => {
			this.onBuildingResponse(data)
		})
	}

	onBuildingResponse(data) {
		// console.log('building response', data)
		this.clear()

		for (let i = 0; i < data.length; i++) {
			const buildingInfo = data[i]
			if (buildingInfo.building_model_id === 0)
				continue

			const spx = buildingInfo.spawn_chunk_x - (buildingInfo.spawn_chunk_x * 2)
			if (this.getTile(spx, buildingInfo.spawn_chunk_y))
				continue;

			this.loadingQueue.push(buildingInfo)
		}
	}

	onAddEntity(data) {
		this.addBuilding(data)
	}

	addBuilding(data) {
		const buildingInfo = data
		const spx = buildingInfo.spawn_chunk_x - (buildingInfo.spawn_chunk_x * 2)
		const spy = buildingInfo.spawn_chunk_y


		const modelBuilding = this.buildingsModels[buildingInfo.building_model_id]

		if (!modelBuilding || !modelBuilding.model) {
			console.warn(`Missing model building: ${buildingInfo.building_model_id}`, buildingInfo)
			return false
		}

		const exists = this.getTile(spx, spy)
		if (exists) {
			if (!this.isOutOfColliderRange(spx, spy, modelBuilding.size_x, modelBuilding.size_z)) {
				if (this.getCollisionTile(spx, spy))
					return false //Added already

				this.onAddCollider(modelBuilding.model.clone(), buildingInfo)
			} else {
				const ct = this.getCollisionTile(spx, spy)
				if (!ct)
					return false //No need to remove - already removed

				this.onRemoveCollider(ct)
			}

			return false
		}

		if (this.isOutOfDrawRange(spx, spy, modelBuilding.size_x, modelBuilding.size_z)) {
			// console.warn(`Out of range: (${buildingInfo.spawn_chunk_x}, ${buildingInfo.spawn_chunk_y}) - ${buildingInfo.name} (${buildingInfo.building_model_id})`, exists, this.buildingLoadQueue.length)
			return false;
		}

		let building = modelBuilding.model.clone()
		this._addBuildingToScene(building, buildingInfo)
		return true
	}

	_addBuildingToScene(building, buildingInfo) {
		const tileWidth = this.gridController.getTileWidth()
		let xOffset = 0
		let yOffset = 0

		const spx = buildingInfo.spawn_chunk_x - (buildingInfo.spawn_chunk_x * 2)
		const spy = buildingInfo.spawn_chunk_y
		const newBuildingAt = new THREE.Vector2(spx, spy)

		const globalPos = new THREE.Vector3((spx * tileWidth) + xOffset, 0, (spy * tileWidth) + yOffset)

		const width = building.userData.tilesX * tileWidth
		const depth = building.userData.tilesZ * tileWidth

		globalPos.x -= width / 2
		globalPos.z -= depth / 2


		// console.debug(`adding building at: ${spx},${spy} - ${buildingInfo.name}`, building, globalPos)

		building.position.set(globalPos.x, globalPos.y, globalPos.z)
		building.rotation.set(0, MathUtils.degToRad(buildingInfo.position_r), 0)
		building.userData.alphaID = building.uuid
		building.userData.isTerrain = true
		building.userData.modelID = buildingInfo.building_model_id
		building.layers.enable(4)

		//TODO we need to remove at some point?
		if (buildingInfo.target_oasis_id !== "0" && buildingInfo.target_oasis_id !== "") {
			// console.log("Adding interactable", buildingInfo)
			this.interactionHandler.addInteractable(building, {
				click: {
					action_type: 'teleport_to_metaverse',
					data: {
						metaverse_addr: buildingInfo.target_oasis_id,
					},
					on_error: null,
					on_success: null,
				},
			})
		}

		const lm = this.modelManager.getLoadedModel(`building-${buildingInfo.building_model_id}`);
		if (lm.animations.length > 0) {
			// console.log("Adding terrain animations: ", lm.animations)
			this.animationManager.addAnimatable(building, lm.animations)
		}

		this.scene.add(building)

		if (!this.isOutOfColliderRange(newBuildingAt.x, newBuildingAt.y, building.userData.tilesX, building.userData.tilesZ)) {
			this.onAddCollider(building, buildingInfo)
		}


		// console.log("Built collider for building at: ", newBuildingAt, building)
		this.setTile(newBuildingAt.x, newBuildingAt.y, newBuildingAt)
		this.activeBuildings[building.uuid] = building
		this.entities.push({
			uuid: building.uuid,
			x: spx,
			z: spy,
			entity: building,
			userData: building.userData,
			info: buildingInfo
		})

		return building
	}

	_addBuildingColliderToScene(building, buildingInfo) {

		const spx = buildingInfo.spawn_chunk_x - (buildingInfo.spawn_chunk_x * 2)
		const spy = buildingInfo.spawn_chunk_y
		const newBuildingAt = new THREE.Vector2(spx, spy)

		if (this.getCollisionTile(newBuildingAt.x, newBuildingAt.y))
			return true //Already added

		console.log("Adding collider to scene", building, buildingInfo)
		let env;
		// if (true) {
		const mesh = this.colliderModelMeshes[buildingInfo.building_model_id].clone();
		building.updateMatrixWorld(true);
		mesh.applyMatrix4(building.matrixWorld)

		this.collisionHandler.addTerrain(building, buildingInfo, mesh)
		env = building
		// } else {
		// 	const simpleCollider = this.buildSimpleCollider(building)
		// 	simpleCollider.uuid = building.uuid
		// 	env = this.collisionHandler.addTerrain(simpleCollider, building)
		// }

		if (!env) {
			console.error("Unable to add building collider")
			return false //Unable to add as collision item
		}

		this.setCollisionTile(newBuildingAt.x, newBuildingAt.y, env.uuid)
		env.userData.alphaID = building.uuid
		return true
	}

	onAddCollider(entity, info) {
		return this._addBuildingColliderToScene(entity, info)
	}

	//Removes the collider from our active colliders
	onRemoveCollider(uuid) {
		console.log("removing collider")
		this.collisionHandler.removeTerrain(uuid, this.scene)
	}

	onRemoveEntity(uuid) {
		this.collisionHandler.removeTerrain(uuid, this.scene)
		this.scene.remove(uuid)

		const geom = this.activeBuildings[uuid]
		if (!geom)
			return

		// console.debug("removeBuilding::Removing:", uuid, geom, this.buildingLoadQueue.length)
		this.scene.remove(geom)
		delete this.activeBuildings[uuid]
	}

	reset() {
		this.buildingsModels = {};
		this.activeBuildings = {}
		this.colliderModelMeshes = {}
	}
}
