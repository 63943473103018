import {DefaultInteraction} from "@alphabatem/ab-sdk-three/src/interactions/interaction";

export class SignSolanaMessageInteraction extends DefaultInteraction {

	_signer

	key() {
		return "sign_solana_msg"
	}

	constructor(signer) {
		super();
		this._signer = signer
	}

	runInteraction(interactionData, metadata, onSuccess, onError) {
		console.log("SignSolanaMessageInteraction", JSON.stringify(interactionData), metadata)
		const message = interactionData.message.replace("$wallet_address", this._signer.getWalletAddr())

		this._signer.sign(message).then((r) => {
			console.log("SignSolanaMessageInteraction", r)
			onSuccess({
				signed: r
			})
		}).catch((e) => {
			onError(e)
		})
	}
}