import {api, apiHelper} from "./api";

export class Meeting {
	prefix() {
		return "metaverses"
	}

	indexTop(page = 0, limit = 50, sort = 'ranking', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	index(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?user=${wallet_addr}&${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	manifest(uid, ts = null) {
		if (ts !== null)
			return api.get(`/static/manifests/${uid}.json?ua=${new Date(ts).getTime()}`)

		return api.get(`/static/manifests/${uid}.json`)
	}

	updateManifest(uid, manifest, signature) {
		return api.put(`${this.prefix()}/${uid}/manifest`, {manifest: manifest, signed: signature})
		// return api.put(`${this.prefix()}/${uid}/manifest`, {manifest: manifest, signed: signature})
	}

	versions(uid) {
		return api.get(`${this.prefix()}/${uid}/versions`)
	}

	version(uid, versionUID) {
		return api.get(`${this.prefix()}/${uid}/versions/${versionUID}`)
	}
}

export default new Meeting()
