import {ManifestController} from "./manifest_controller";
import * as THREE from "three";

export class EnvironmentController extends ManifestController {

    update(scene, object, callback) {
        console.log("EnvironmentController.update()", object);

        scene.spawn_point = object.spawn_point

        // scene.background = new THREE.Color(this.rgbWrap(object.background));
        // scene.fog.start = object.fog.start;
        // scene.fog.end = object.fog.end;
        // scene.fog.color = new THREE.Color(this.rgbWrap(object.fog.color));
        // scene.fog.needsUpdate = true

        // scene.fog = new THREE.Fog(object.fog.color, object.fog.start, object.fog.end);
        callback();
    }

    toManifest(obj) {
        return {
            spawn_point: [
              obj.position.x, obj.position.y, obj.position.z
            ],
            // background: obj.background,
            // fog: {
            //     start: obj.fog.start,
            //     end: obj.fog.end,
            //     color: obj.fog.color
            // }
        };
    }
}
