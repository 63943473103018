import * as THREE from "three";
import {TileMap} from "./tile_map";

export class GridExtension {

	scene

	entities = []

	gridController

	modelManager
	collisionHandler
	interactionHandler

	//Queue the entities to be added to the scene so not to eat users cpu
	loadingQueue = []

	x = 0
	z = 0
	r = 3

	tiles = new TileMap()
	collisionTiles = new TileMap()

	constructor(gridController, modelManager, animationManager, collisionHandler, interactionHandler, scene) {
		this.scene = scene
		this.modelManager = modelManager
		this.animationManager = animationManager
		this.collisionHandler = collisionHandler
		this.interactionHandler = interactionHandler
		this.gridController = gridController
	}

	async update(newX, newZ) {
		if (this.x !== newX || this.z !== newZ) {
			this.onGridChange(newX, newZ)
			this.x = newX
			this.z = newZ
		}

		this.clear()
		this.processQueue() //Add a building to scene
	}

	/**
	 * Called when the grid x/z changes
	 * @param x
	 * @param z
	 */
	onGridChange(x,z) {
		//STUB
	}

	/**
	 * Called when an entity should be removed from the grid
	 * @param uuid
	 */
	onRemoveEntity(uuid) {
		//Stub
	}

	/**
	 * Called when a collider should be added for a given entity
	 * @param entity
	 * @param info
	 */
	onAddCollider(entity, info) {
		//Stub
	}

	/**
	 * Called when a collider should be removed from the grid
	 * @param uuid
	 */
	onRemoveCollider(uuid) {
		//Stub
	}


	/**
	 * Called when an entity should be added to the grid
	 * @param data
	 */
	onAddEntity(data) {
		//STUB
	}


	reset() {
		this.entities = [];
	}

	processQueue() {
		if (this.loadingQueue.length > 500)
			this.loadingQueue = this.loadingQueue.slice(-200)

		const maxLen = Math.min(this.loadingQueue.length, 50)
		for (let i = 0; i < maxLen; i++) {
			const data = this.loadingQueue.pop()
			if (!data)
				break

			if (this.onAddEntity(data))
				break
		}
	}

	clear() {
		const inRange = []
		const toBeDeleted = []
		this.entities.forEach((b) => {

			if (this.isOutOfDrawRange(b.x, b.z, b.userData.tilesX, b.userData.tilesZ)) {
				toBeDeleted.push(b.uuid)
				this.deleteTile(b.x, b.z)
				this.onRemoveEntity(b.uuid)

			} else {

				//Check if we should add collider
				if (!this.isOutOfColliderRange(b.x, b.z, b.userData.tilesX, b.userData.tilesZ)) {
					this.onAddCollider(b.entity, b.info)
				} else if (this.getCollisionTile(b.x, b.z)) {
					this.deleteCollisionTile(b.x, b.z)
					this.onRemoveCollider(b.uuid)
				}

				inRange.push(b)
			}
		})

		if (toBeDeleted.length < 1)
			return

		this.entities = inRange;
	}

	intersectRect(a, b) {
		return !(a.left > b.right // R1 is right to R2
			|| a.right < b.left // R1 is left to R2
			|| a.top < b.bottom // R1 is above R2
			|| a.bottom > b.top)
	}

	isOutOfColliderRange(bx, by, tx = 1, ty = 1) {
		const r = this.gridController.getRadius()
		return this._isOutOfRange(bx, by, tx, ty, r)
	}

	isOutOfDrawRange(bx, by, tx = 1, ty = 1) {
		const r = this.gridController.getDrawRadius()
		return this._isOutOfRange(bx, by, tx, ty, r)
	}

	_isOutOfRange(bx, by, tx = 1, ty = 1, r) {
		//Building rect
		const bnx = bx - (bx * 2)
		const bxMin = bnx
		const bzMin = by - (ty === 0 ? 1 : ty)
		const bxMax = bnx + (tx === 0 ? 1 : tx)
		const bzMax = by

		//Player scan range
		const nx = this.x
		const minGridX = nx - r
		const maxGridX = nx + r
		const minGridZ = this.z - r
		const maxGridZ = this.z + r

		return !this.intersectRect({
			top: maxGridZ,
			left: minGridX,
			bottom: minGridZ,
			right: maxGridX
		}, {
			top: bzMax,
			left: bxMin,
			bottom: bzMin,
			right: bxMax
		})
	}

	buildSimpleCollider(building) {
		const collider = new THREE.Box3().setFromObject(building);
		const dimensions = new THREE.Vector3().subVectors(collider.max, collider.min);
		const boxGeo = new THREE.BoxBufferGeometry((dimensions.x / 100) * 70, dimensions.y, (dimensions.z / 100) * 70);
		const matrix = new THREE.Matrix4().setPosition(dimensions.addVectors(collider.min, collider.max).multiplyScalar(0.5));
		boxGeo.applyMatrix4(matrix);

		const mesh = new THREE.Mesh(boxGeo, new THREE.MeshBasicMaterial({visible: false}));
		mesh.geometry.computeBoundingBox()

		return mesh
	}

	setTile(x, y, val) {
		this.tiles.setTile(x, y, val)
	}

	getTile(x, y) {
		return this.tiles.getTile(x, y)
	}

	deleteTile(x, y) {
		this.tiles.deleteTile(x, y)
	}

	setCollisionTile(x, y, val) {
		this.collisionTiles.setTile(x, y, val)
	}

	getCollisionTile(x, y) {
		return this.collisionTiles.getTile(x, y)
	}

	deleteCollisionTile(x, y) {
		this.collisionTiles.deleteTile(x, y)
	}
}