import * as THREE from "three";
import {ManifestController} from "./manifest_controller";
import {RectAreaLightHelper} from 'three/examples/jsm/helpers/RectAreaLightHelper.js';

export class LightController extends ManifestController {
	shadowCameraDistance = 1000;

	shadowQuality = 'medium'

	cameraHelper = null;
	lightHelper = null

	constructor(modelManager, debugMode = false) {
		super()
		this.debugMode = debugMode
	}

	setQuality(quality) {
		this.shadowQuality = quality
	}

	add(scene, light, folder = null, callback = () => {
	}, addToScene = true) {
		// console.log("light", light)

		let obj;
		switch (light.type) {
			case "ambient":
				//Ambient
				obj = this._addAmbient(light)
				break;
			case "hemisphere":
				// Global Light
				obj = this._addHemisphere(light)
				break;

			case "directional":
				// Global Light
				obj = this._addDirectional(light, this.shadowQuality)
				break;
			case "spot":
				obj = this._addSpot(light, this.shadowQuality)
				if (addToScene && light.target) {
					// console.log("Adding light target", obj.target, light.target)
					scene.add(obj.target)
					obj.target.position.set(light.target[0], light.target[1], light.target[2])
					obj.target.updateMatrixWorld();
				}

				break
			case "point":
				// Sun Light
				obj = this._addPoint(light, this.shadowQuality)
				break
			case "rectarea":
				obj = this._addPlane(light)
				break
		}

		if (addToScene) {
			// console.log("Adding object to scene: ", obj)
			scene.add(obj);
		}

		if (this.debugMode) {
			scene.remove(this.cameraHelper)
			scene.remove(this.lightHelper)

			this.cameraHelper = null
			this.lightHelper = null

			switch (light.type) {
				case "spot":
					this.lightHelper = new THREE.SpotLightHelper(obj, light.color)
					this.cameraHelper = new THREE.CameraHelper(obj.shadow.camera)
					break;
				case "point":
					this.lightHelper = new THREE.PointLightHelper(obj, 1)
					this.cameraHelper = new THREE.CameraHelper(obj.shadow.camera)
					break
				case "directional":
					this.lightHelper = new THREE.DirectionalLightHelper(obj, 1)
					this.cameraHelper = new THREE.CameraHelper(obj.shadow.camera)
					break
				case "hemisphere":
					this.lightHelper = new THREE.HemisphereLightHelper(obj, 1)
					break
				case "rectarea":
					this.lightHelper = new RectAreaLightHelper(obj)
					break
			}

			if (this.cameraHelper !== null)
				scene.add(this.cameraHelper)
			if (this.lightHelper !== null)
				scene.add(this.lightHelper)
		}

		//Set shadows after being added
		switch (light.type) {
			case "spot":
			case "point":
			case "directional":
				obj.castShadow = light.shadow.enabled;
				this._setShadows(obj, light, this.shadowQuality)
				break
		}

		// obj.layers.set(1)

		return callback()
	}


	_addAmbient(light) {
		const ambient = new THREE.AmbientLight(0xffffff, light.intensity);
		ambient.userData.alphaID = light.id
		ambient.color.set(this.rgbWrap(light.color));

		return ambient
	}

	_addHemisphere(light) {
		const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, light.intensity);

		hemiLight.userData.alphaID = light.id
		hemiLight.color.set(this.rgbWrap(light.color_sky));
		hemiLight.groundColor.set(this.rgbWrap(light.color_ground));

		return hemiLight
	}

	_addDirectional(light) {
		const directLight = new THREE.DirectionalLight(0xffffff, light.intensity);

		directLight.userData.alphaID = light.id
		directLight.color.set(this.rgbWrap(light.color));
		directLight.position.set(light.position[0], light.position[1], light.position[2]);

		if (light.rotation)
			directLight.rotation.set(light.rotation[0], light.rotation[1], light.rotation[2]);

		// directLight.target.position.set(0, 0, 0);


		return directLight
	}

	_addSpot(light) {
		const spotLight = new THREE.SpotLight(0xffffff, light.intensity, light.distance, light.angle, light.penumbra, light.decay);
		spotLight.color.set(this.rgbWrap(light.color));

		spotLight.userData.alphaID = light.id
		spotLight.position.set(light.position[0], light.position[1], light.position[2]);

		if (light.target) {
			// console.log("Setting spot target: ", light.target)
			spotLight.target.position.set(light.target[0], light.target[1], light.target[2]);
		}


		return spotLight
	}

	_addPoint(light) {
		const sphere = new THREE.SphereGeometry(0.1, 12, 12);
		const pointLight = new THREE.PointLight(0xffffff, light.intensity, light.distance, light.decay);
		pointLight.color.set(this.rgbWrap(light.color));

		pointLight.userData.alphaID = light.id
		pointLight.position.set(light.position[0], light.position[1], light.position[2]);
		pointLight.add(new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({color: this.rgbWrap(light.color)})));

		if (light.rotation)
			pointLight.rotation.set(light.rotation[0], light.rotation[1], light.rotation[2]);

		return pointLight
	}

	_addPlane(light) {
		// const sphere = new THREE.SphereGeometry( 0.1, 12, 12 );
		const planeLight = new THREE.RectAreaLight(0xffffff, light.intensity, light.width, light.height);
		planeLight.color.set(this.rgbWrap(light.color));

		planeLight.userData.alphaID = light.id
		planeLight.position.set(light.position[0], light.position[1], light.position[2]);
		// planeLight.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: this.rgbWrap(light.color) } ) ) );

		if (light.rotation)
			planeLight.rotation.set(light.rotation[0], light.rotation[1], light.rotation[2]);

		return planeLight
	}

	_setShadows(obj, light, shadowQuality) {
		if (!light.shadow.enabled) {
			return
		}

		obj.shadow.bias = light.shadow.bias;
		obj.shadow.mapSize.width = shadowQuality; // default
		obj.shadow.mapSize.height = shadowQuality; // default
		obj.shadow.camera.near = light.shadow.near || 0.5; // default
		obj.shadow.camera.far = light.shadow.far || 500; // default

		obj.shadow.camera.left = -this.shadowCameraDistance;
		obj.shadow.camera.right = this.shadowCameraDistance;
		obj.shadow.camera.top = this.shadowCameraDistance;
		obj.shadow.camera.bottom = -this.shadowCameraDistance;
		obj.shadow.camera.updateProjectionMatrix()
	}

	toManifest(light) {
		const lightType = light.type.toLowerCase().replace("light", "")

		const manifestObj = {
			id: light.userData.alphaID,
			type: lightType,
			position: [light.position.x, light.position.y, light.position.z] || [0, 0, 0],
			intensity: light.intensity || 1,
		};

		if (light.rotation) {
			manifestObj.rotation = [light.rotation.x, light.rotation.y, light.rotation.z] || [0, 0, 0]
		}

		if (light.shadow) {
			manifestObj.shadow = {
				enabled: light.castShadow,
				bias: light.shadow.bias,
				near: light.shadow.camera.near,
				far: light.shadow.camera.far,
			}
		}

		switch (light.type) {
			case "SpotLight":
				manifestObj.color = `#${light.color.getHexString()}` || '#ffffff';
				manifestObj.target = [light.target.position.x, light.target.position.y, light.target.position.z] || [0, 0, 0];
				manifestObj.distance = light.distance;
				manifestObj.decay = light.decay;
				manifestObj.angle = light.angle;
				manifestObj.penumbra = light.penumbra;
				break;
			case "PointLight":
				manifestObj.color = `#${light.color.getHexString()}` || '#ffffff';
				manifestObj.distance = light.distance;
				manifestObj.decay = light.decay;
				break;
			case "HemisphereLight":
				manifestObj.color_sky = `#${light.color.getHexString()}` || '#ffffff';
				manifestObj.color_ground = `#${light.color.getHexString()}` || '#ffffff';
				break;
			case "AmbientLight":
				manifestObj.color = `#${light.color.getHexString()}` || '#ffffff';
				break;
			case "RectAreaLight":
				manifestObj.color = `#${light.color.getHexString()}` || '#ffffff';
				break;
		}


		console.log("toLightManifest", {
			light: light,
			manifest: manifestObj,
		})
		return manifestObj
	}
}
