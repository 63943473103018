// export const VoxelTypes = {
//     GRASS: 'grass',
//     DIRT: 'dirt',
//     IRON: 'iron',
//     REDSTONE: 'redstone',
//     NETHER_BRICK: 'nether-brick',
//     RED_SANDSTONE: 'red-sandstone',
//     WATER: 'water',
//     SNOW: 'snow',
//     LAVA: 'lava',
// }

export const VoxelTypes = {
    GRASS: 0,
    DIRT: 1,
    IRON: 2,
    REDSTONE: 3,
    NETHER_BRICK: 4,
    RED_SANDSTONE: 5,
    WATER: 6,
    LAVA: 7,
    SNOW: 8,
}

export const Cube = {
    TOP: 0,
    BOTTOM: 1,
    SIDE_1: 2,
    SIDE_2: 3,
    SIDE_3: 4,
    SIDE_4: 5,
}
