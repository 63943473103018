export class TileMap {
	tiles = {}

	constructor() {
	}

	setTile(x, y, val) {
		if (!this.tiles[x])
			this.tiles[x] = {}

		this.tiles[x][y] = val
	}

	getTile(x, y) {
		if (!this.tiles[x])
			return null
		return this.tiles[x][y]
	}

	deleteTile(x, y) {
		if (!this.tiles[x])
			return null

		delete this.tiles[x][y]
		if (this.tiles[x].length === 0)
			delete this.tiles[x]
	}

	getPopulatedTilesInRadius(ox, oz, radius, max = -1) {
		const populated = [];
		for (let x = ox - (radius / 2); x < ox + (radius / 2); x++) {
			for (let z = oz - (radius / 2); x < ox + (radius / 2); z++) {
				if (this.getTile(x, z))
					populated.push({x: x, z: z})

				if (max > 0 && populated.length >= max)
					break
			}
		}
		return populated;
	}

	getInsideOutEmptyTilesInRadius(ox, oz, radius, startRadius = 0, max = -1) {
		const empty = [];

		console.log(`getInsideOutEmptyTilesInRadius - ${startRadius} -> ${radius}`, max, ox,oz)
		for (let r = startRadius; r < startRadius + radius; r++) {

			let dX = 0
			let dY = 0
			let ldX = ox - startRadius
			let ldY = oz - startRadius

			//Loop direction 4 times
			for (let dir = 0; dir < 4; dir++) {
				dX = dir === 0 ? 1 : dir === 2 ? -1 : dX
				dY = dir === 1 ? 1 : dir === 3 ? -1 : dY

				const isX = dir === 0 || dir === 2
				const isY = dir === 1 || dir === 3


				//Loop in the direction for the radius
				for (let dr = 0; dr < r * 2; dr++) {
					ldX = isX ? ox - (startRadius * dX) + (dX * dr) : ldX
					ldY = isY ? oz - (startRadius * dY) + (dY * dr): ldY

					if (this.getTile(ldX, ldY))
						continue

					empty.push({x: ldX, z: ldY})


					if (max > 0 && empty.length >= max)
						break
				}
			}


		}

		return empty;
	}

	getEmptyTilesInRadius(ox, oz, radius, max = -1) {
		const empty = [];
		for (let x = ox - (radius / 2); x < ox + (radius / 2); x++) {
			for (let z = oz - (radius / 2); x < ox + (radius / 2); z++) {
				if (this.getTile(x, z))
					continue
				empty.push({x: x, z: z})

				if (max > 0 && empty.length >= max)
					break
			}
		}
		return empty;
	}
}