import {Transaction} from "@solana/web3.js";
import {DefaultInteraction} from "@alphabatem/ab-sdk-three/src/interactions/interaction";

export class ExecuteTransactionInteraction extends DefaultInteraction {

	_signer

	key() {
		return "execute_txn"
	}

	constructor(signer) {
		super();
		this._signer = signer
	}

	runInteraction(interactionData, metadata, onSuccess, onError) {
		console.log("ExecuteTransactionInteraction", JSON.stringify(interactionData), metadata)

		const transaction = Transaction.from(Buffer.from(interactionData.txn, 'base64'))
		console.log("TXN", transaction)

		this._signer.signAndSend(transaction).then((r) => {
			console.log("ExecuteTransactionIntraction", r)
			onSuccess(r)
		}).catch((e) => {
			onError(e)
		})
	}
}