import * as THREE from 'three'
import {Vector2} from 'three'
import {TileMap} from "./tile_map";

export class GridController {
	// TEXTURES
	textureLoader = new THREE.TextureLoader();
	sandBaseColor = this.textureLoader.load("./assets/city/metal/KB3D_HTS_MetalDarkGrey_basecolor.png");
	roughnessMap = this.textureLoader.load("./assets/city/metal/KB3D_HTS_MetalDarkGrey_metallic-KB3D_HTS_MetalDarkGrey_roughness.png");
	// sandNormalMap = this.textureLoader.load("./assets/city/metal/Sand 002_NRM.jpg");
	// sandHeightMap = this.textureLoader.load("./assets/city/metal/Sand 002_DISP.jpg");
	// sandAmbientOcclusion = this.textureLoader.load("./assets/city/metal/Sand 002_OCC.jpg");

	buildings = []

	floor

	model = null
	WIDTH = 10
	colliderRadius = 1
	drawRadius = 5

	canvasX
	canvasZ
	gridX
	gridZ
	// directionX = document.querySelector('.position .direction .x')
	// directionZ = document.querySelector('.position .direction .z')
	cardinalPoint
	previousX = 0
	previousZ = 0

	gridPosition = new THREE.Vector3(0,0,0)


	constructor(width, radius, colliderRadius) {
		this.WIDTH = width
		this.colliderRadius = colliderRadius
		this.drawRadius = radius

		this.canvasX = document.querySelector('#canvas-x')
		this.canvasZ = document.querySelector('#canvas-z')
		this.gridX = document.querySelector('#grid-x')
		this.gridZ = document.querySelector('#grid-z')
		this.cardinalPoint = document.querySelector('#cardinal-point')
	}

	setLocalPlayer(model) {
		console.log("GridController::playerSet", model)
		this.model = model
	}

	getModelPosition() {
		if (this.model === null)
			return {x: 0, y: 0, z: 0}

		return this.model.position
	}

	setRadius(r) {
		this.colliderRadius = r
	}

	setDrawRadius(r) {
		this.drawRadius = r
	}

	getBearing() {
		return this.gridToCardinalPoints(this.previousX, this.previousZ)
	}

	update() {
		const newGridX = this.getGridPosition().x
		const newGridZ = this.getGridPosition().z

		if (this.newGridPosition(newGridX, newGridZ)) {
			this.gridX.innerText = newGridX.toString()
			this.gridZ.innerText = newGridZ.toString()
			this.cardinalPoint.innerText = this.gridToCardinalPoints(newGridX, newGridZ).charAt(0)

			this.previousX = newGridX
			this.previousZ = newGridZ
			this._updateFloor()
		}
	}

	newGridPosition(newX, newZ) {
		return this.previousX !== newX || this.previousZ !== newZ
	}

	_updateFloor() {
		if (!this.floor)
			return

		this.floor.position.set(
			Math.round((this.getModelPosition().x) / this.WIDTH) * this.WIDTH,
			0,
			Math.round((this.getModelPosition().z) / this.WIDTH) * this.WIDTH
		)
		// console.log("tiles", this.tiles)
	}

	gridToCardinalPoints(newX, newZ, vector = false) {
		const directionX = newX - this.previousX
		const directionZ = newZ - this.previousZ

		if (vector) return new Vector2(directionX, directionZ)

		if (directionX === 0 && directionZ === 1) return "NORTH"
		if (directionX === 0 && directionZ === -1) return "SOUTH"
		if (directionX === 1 && directionZ === 0) return "EAST"

		// if (directionX === -1 && directionZ === 0)
		return "WEST"
	}

	getGridPosition() {
		const gpx = Math.round((this.getModelPosition().x) / this.WIDTH);
		this.gridPosition.x = gpx - (gpx * 2);
		this.gridPosition.z = Math.round((this.getModelPosition().z) / this.WIDTH);

		return this.gridPosition
		// return new THREE.Vector3(
		// 	gpx - (gpx * 2),
		// 	0,
		// 	Math.round((this.getModelPosition().z) / this.WIDTH)
		// )
	}

	getRadius() {
		return this.colliderRadius
	}

	getDrawRadius() {
		return this.drawRadius
	}

	getTileWidth() {
		return this.WIDTH
	}

	generateFloor() {
		const radius = this.drawRadius * 2; //We want more tiles than rendered buildings
		const geometry = new THREE.PlaneGeometry(this.WIDTH + 0.1, this.WIDTH + 0.1);
		const material = new THREE.MeshLambertMaterial({
			color: 0x5e5d5d,
			map: this.sandBaseColor,
			// roughness: this.roughnessMap,
			// normalMap: this.sandNormalMap,
			// displacementMap: this.sandHeightMap, displacementScale: 0.1,
			// aoMap: this.sandAmbientOcclusion,
		})

		this.floor = new THREE.InstancedMesh(geometry, material, Math.pow(2 * radius + 1, 2))
		this.floor.castShadow = false
		this.floor.receiveShadow = true

		const dummy = new THREE.Object3D();
		dummy.rotation.x = -Math.PI / 2

		let i = 0
		for (let x = -radius; x < radius + 1; x++) {
			for (let z = -radius; z < radius + 1; z++) {
				dummy.position.set(x * this.WIDTH, 0, z * this.WIDTH)
				dummy.updateMatrix()
				this.floor.setMatrixAt(i, dummy.matrix)
				i++
			}
		}

		// this.floor.instanceMatrix.needsUpdate = true

		return this.floor
	}

	reset() {
		this.buildings = [];
		if (this.floor)
			this.floor.dispose()
	}
}
