import {PublicKey} from "@solana/web3.js";
import {DefaultInteraction} from "@alphabatem/ab-sdk-three/src/interactions/interaction";
import {getAssociatedTokenAddress} from "@solana/spl-token";

export class TokenBalanceInteraction extends DefaultInteraction {

	_signer

	key() {
		return "token_balance"
	}

	constructor(signer) {
		super();
		this._signer = signer
	}

	async runInteraction(interactionData, metadata, onSuccess, onError) {
		console.log("TokenBalanceInteraction", JSON.stringify(interactionData), metadata)

		const tokenAddr = interactionData['@mint']

		const conn = this._signer.getConnection()
		const walletAddr = this._signer.getWalletAddr()

		const tokenAccount = await getAssociatedTokenAddress(new PublicKey(tokenAddr), new PublicKey(walletAddr)).catch((e) => {onError(e)})
		if (!tokenAccount)
			return

		const balance = await conn.getTokenAccountBalance(tokenAccount).catch((e) => {onError(e)})
		console.log(balance)
		if (!balance)
			return

		onSuccess(balance.value.amount)
	}
}