import {Connection, PublicKey} from "@solana/web3.js";

export class Signer {

	walletState
	wallet

	conn

	publicKey

	constructor(wallet, walletState, rpcUri = "https://solana-mainnet.g.alchemy.com/v2/k4wb7PGZkC8_7ZtJNQYhn3tEoPfq6dyE") {
		this.wallet = wallet
		this.walletState = walletState
		this.conn = new Connection(rpcUri, "finalized")

		try {
			this.publicKey = new PublicKey(this.walletState.address)
		} catch (e) {
			//
		}
	}

	/**
	 * Returns raw connection
	 * @returns Connection
	 */
	getConnection() {
		return this.conn
	}

	/**
	 * Returns the wallet address of signer
	 * @returns string
	 */
	getWalletAddr() {
		return this.walletState.address
	}

	/**
	 * Returns if the signer is web3
	 * @returns boolean
	 */
	isWeb3() {
		return this.walletState.isWeb3
	}

	async sign(msg) {
		if (this.walletState.isWeb3)
			return await this.signWeb3(msg)

		return this.signWeb2()
	}

	/**
	 * Verify via web2 login
	 */
	signWeb2() {
		console.log("Signing with", this.walletState.jwt_token)
		const enc = new TextEncoder()
		const data = enc.encode(this.walletState.jwt_token)

		const d = []
		for (let i = 0; i < data.length; i++) {
			d.push(data[i])
		}

		return {
			publicKey: this.walletState.address,
			signature: {
				data: d,
				type: "JWT"
			},
		}
	}

	async signWeb3(msg) {
		const r = await this.wallet.signMessage(new TextEncoder().encode(msg), "utf8").catch(e => {})
		if (!r)
			throw new Error("sign message failed")

		return {
			publicKey: this.walletState.address,
			signature: r,
		}
	}

	async signAndSend(txn) {
		if (!this.isWeb3())
			return "TxWeb2" //TODO Implement

		console.log(this.wallet, new PublicKey(this.walletState.address).toString())
		return await this.wallet.sendTransaction(txn, this.conn)
	}

	/**
	 * Get Balance of the user
	 * @returns {Promise<number|*>}
	 */
	async getBalance() {
		if (!this.isWeb3())
			return 0

		return await this.conn.getBalance(new PublicKey(this.walletState.address))
	}

	async signMessage(msg) { return this.wallet.signMessage(msg)}
	async signTransaction(txs) { return this.wallet.signTransaction(txs)}
	async signAllTransactions(txs) { return this.wallet.signTransaction(txs)}

}