export class ManifestInterface {
    debugMode = false;

    setDebug(debug) {
        this.debugMode = debug
    }

    add(scene, data, folder = null, callback = () => {}, addToScene = true) {
        //Stub

        return callback()
    }

    update(scene, data) {
        //Stub
    }

    toManifest(obj) {
        //STUB
        return {}
    }
}