import * as THREE from "three";
import {GridController} from './city/GridController'
import {BuildingManager} from './city/BuildingManager'
import Stats from 'three/examples/jsm/libs/stats.module'
import {SceneryManager} from "./city/SceneryManager";
import {MetaverseInstance} from "./metaverse";

export class CityInstance extends MetaverseInstance {

	gridController
	buildingManager
	sceneryManager

	stats

	sunLight


	constructor(manifest, signer, debugMode = false, editor = false) {
		super(manifest, signer, debugMode, editor)
		this.stats = Stats()
		document.body.appendChild(this.stats.dom)
		this.isCity = true


		this.setupCityControllers()
	}

	restartCity(manifest) {
		this.isCity = true
		this.reset()
		this.manifest = manifest
		this.load()
		this.setupControllers()
		this.start()
	}


	/**
	 * Enables loading from local files
	 */
	enableLocalLoad() {
		super.enableLocalLoad()
		if (!this.isCity)
			return

		this.buildingManager.setLocalLoad(true)
	}

	setupCityControllers() {
		console.log("City instance setup: ", this.isCity)
		if (this.isCity) {
			this.gridController = new GridController(20, 10, 1)
			this.buildingManager = new BuildingManager(this.gridController, this.getModelManager(), this.getManager("animation"), this.getCollisionHandler(), this.getInteractionHandler(), this.scene)

			this.controllers["grid"] = this.gridController //So it catches update() calls
			this.controllers["buildings"] = this.buildingManager //So it catches update() calls
		}
	}

	onPlayerAdd(data) {
		super.onPlayerAdd(data);
		if (this.gridController)
			this.gridController.setLocalPlayer(this.playerManager.localPlayer)
	}

	animate() {
		super.animate();

		if (this.gridController) {
			const {x, z} = this.gridController.getGridPosition()
			this.gridController.update()

			const pos = this.gridController.getModelPosition()
			this._updateCityFloor(pos.x, pos.z)
			this._updateCityLighting(pos.x, pos.z)

			if (this.buildingManager)
				this.buildingManager.update(x, z)

			if (this.sceneryManager)
				this.sceneryManager.update(x, z)
		}

		if (this.stats)
			this.stats.update();
	}

	models() {
		super.models()

		if (this.buildingManager)
			this.buildingManager.loadFromShadow();


		//Build here as we have access to entities field
		if (this.isCity) {
			this.sceneryManager = new SceneryManager(this.controllers["grid"], this.controllers["buildings"], this.getModelManager(), this.getManager("animation"), this.getCollisionHandler(), this.getInteractionHandler(), this.scene, this.manifest.entities)
		}
	}

	entities() {
		if (!this.isCity) {
			super.entities();
			return
		}
		//We use entities for scenery manager instead
		// this._entities() //Manifest entities

		if (this.sceneryManager)
			this.sceneryManager.load();

		if (this.buildingManager)
			this.buildingManager.pullBuildings(0, 0)
	}


	terrain() {
		super.terrain()

		if (this.gridController) {
			const floor = this.gridController.generateFloor();
			this.scene.add(floor)

			const geometry = new THREE.BoxGeometry(30000, 1, 3000, 30, 4, 30);
			const material = new THREE.MeshBasicMaterial({
				color: 0x00000
			})

			this.cityFloor = new THREE.Mesh(geometry, material)
			this.cityFloor.visible = false
			this.cityFloor.receiveShadow = true
			this.cityFloor.position.y = -0.5
			// cityFloor.rotation.x = -Math.PI / 2

			//Pull the item and assign its alphaID again
			const env = this.getCollisionHandler().addTerrain(this.cityFloor, {name: "floor"})
		}
	}

	_updateCityFloor(x, z) {
		if (!this.cityFloor)
			return

		this.cityFloor.position.x = x
		this.cityFloor.position.z = z
	}

	_updateCityLighting(x, z) {
		if (!this.sunLight)
			return

		this.sunLight.position.x = x
		this.sunLight.position.z = z

		//TODO
	}

	lighting() {
		super.lighting();

		const lightID = this.manifest.lighting[0].id
		this.sunLight = this.search(lightID)
	}

	setQuality(quality) {
		switch (quality) {
			case "low":
				this.gridController.setRadius(1)
				this.gridController.setDrawRadius(6)
				break
			case "medium":
				this.gridController.setRadius(1)
				this.gridController.setDrawRadius(12)
				break
			case "high":
				this.gridController.setRadius(1)
				this.gridController.setDrawRadius(18)
				break
			case "very_high":
				this.gridController.setRadius(1)
				this.gridController.setDrawRadius(24)
				break
		}
	}

	// @override
	skybox() {
		const hourInDay = new Date().getHours()
		let uris = [
			'/assets/editor/skyboxes/g2/skybox_right_2x.png',
			'/assets/editor/skyboxes/g2/skybox_left_2x.png',
			'/assets/editor/skyboxes/g2/skybox_up_2x.png',
			'/assets/editor/skyboxes/g2/skybox_down_2x.png',
			'/assets/editor/skyboxes/g2/skybox_front_2x.png',
			'/assets/editor/skyboxes/g2/skybox_back_2x.png',
		]

		if (hourInDay > 11 && hourInDay < 18) {
			uris = [
				'/assets/editor/skyboxes/sunny_day/skybox_right.png',
				'/assets/editor/skyboxes/sunny_day/skybox_left.png',
				'/assets/editor/skyboxes/sunny_day/skybox_up.png',
				'/assets/editor/skyboxes/sunny_day/skybox_down.png',
				'/assets/editor/skyboxes/sunny_day/skybox_front.png',
				'/assets/editor/skyboxes/sunny_day/skybox_back.png',
			]
		}

		const loader = new THREE.CubeTextureLoader();
		loader.load(uris, (texture) => {
			this.scene.raw().background = texture;
			// this.envMap = texture
			// this.scene.raw().environment = this.envMap;

			this.envMap = pmremGenerator.fromCubemap(texture).texture;
			this.scene.raw().environment = this.envMap;
			pmremGenerator.dispose();
		});
		const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
		pmremGenerator.compileEquirectangularShader();
	}


	/**
	 *
	 */

	track = [];

	interactables = {
		"player": ["SM_CartMountain_Mountain"]
	}

	onVoiceStart() {
		console.log("onVoiceStart")
		this.speechManager.start()
	}

	onVoiceStop() {
		console.log("onVoiceStop")
		this.speechManager.stop()
	}

	onKeyDown(e) {
		//
	}

	onKeyPress(event) {
		//
		event.stopPropagation()

		switch (event.key) {
			// case 'i':
			// 	this.show.inventory = !this.show.inventory

			// 	if (this.show.inventory)
			// 		document.getElementById('inventory').focus();
			// 	break;
			case 'e':
				if (this.showTeleportPrompt) {
					this.showTeleportPrompt = false
					this.changeScene(this.eventListenerDetails.metaverse_addr)
				}
				break;
			case 'q':
				if (this.showTeleportPrompt) this.showTeleportPrompt = false
				break;
		}

	}

	reset() {
		super.reset()

		//Clear existing scene
		if (this.gridController) {
			this.gridController.reset()
			this.gridController = null
		}
		if (this.buildingManager) {
			this.buildingManager.reset()
			this.buildingManager = null
		}
		if (this.sceneryManager) {
			this.sceneryManager.reset()
			this.sceneryManager = null
		}

		delete this.controllers["grid"]
		delete this.controllers["buildings"]
	}

	/**
	 * Update game settings
	 * {
	 * 		quality: "medium",
	 * 		sound_enabled: true,
	 * 		music_volume: 100,
	 * 		effect_volume: 100,
	 * 		voice_enabled: false,
	 * 		voice_volume: 100,
	 * 		bloom_enabled: true,
	 * 		bloom_level: 1.0,
	 * 		antialiasing: true,
	 * 		brightness: 1.0,
	 * 	}
	 * @param settings
	 */
	setSettings(settings) {
		super.setSettings(settings)
		this.setQuality(settings.quality)

		//Audio controller
		this._updateBrightness(settings.brightness)


	}

	_updateBrightness(brightness) {

	}
}
