import {v4 as uuidv4} from "uuid"
import * as THREE from "three"

export class Editor {


	//Copy of our manifest file
	manifest = null;

	draftInProgress = false;
	// draft = {};

	//ThreeJS Scene
	scene = null;

	//Active menu
	active = {menu: "items", data: {}};

	ZERO_VECTOR = new THREE.Vector2(0, 0)

	lastHighlight = null

	constructor(manifest, scene, camera) {
		this.manifest = manifest
		// this.draft = manifest
		this.scene = scene
		this.mouse = {x: 0, y: 0};
		this.camera = camera
		this.raycaster = new THREE.Raycaster()
		this.raycaster.far = 40

		// if (this.manifest.terrain.length === 0) {
		this._drawGridPlane()
		// }
	}

	_setDraft() {
		if (!this.draftInProgress) {
			this.draftInProgress = true;
			// this.draft = Object.assign({}, this.manifest)
		}
	}


	_drawGridPlane() {
		const geometry = new THREE.PlaneGeometry(500, 500);
		const material = new THREE.MeshStandardMaterial({visible: false, emissiveIntensity: 0.1});
		const plane = new THREE.Mesh(geometry, material);
		plane.name = "baseEditorPlane"
		plane.rotation.x = -Math.PI / 2
		plane.position.y = -1

		const grid = new THREE.GridHelper(160, 20, "white", "grey");
		grid.position.y = -1
		this.scene.add(grid, plane)
	}

	//On add (manifest add)
	//Add the element
	//Add the respective helper
	//Bind our controls/view to edit the properties of scene object

	//On update (manifest update)
	//Get the correct manifest element
	//Get the scene object
	//Update properties of scene object

	onManifestUpdate(key, value, data = {}) {
		console.log("onManifestUpdate", {key, value, data, active: this.active})

		//Check if is a menu command
		if (this.isMenuCommand(key, value)) {
			return
		}

		this._setDraft()

		//TODO Check for actions too key === "actions"
		if (key === "models") {
			if (this.manifest[key] === null)
				this.manifest[key] = {};

			this.manifest[key][value.id] = value.scene
			console.log("onManifestUpdate - manifest:", this.manifest)
			return;
		}

		this._updateManifest(key, value)
		console.log("onManifestUpdate - manifest:", this.manifest)
	}

	_searchParent(obj) {
		let isec = obj
		while (!isec.userData.alphaID && isec.parent) {
			if (isec.name === "Scene" || isec.parent.name === "Scene")
				return null

			isec = isec.parent
			break
		}

		if (isec.userData.length === 0)
			return null

		return isec
	}

	_raycast(e) {
		this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
		this.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

		this.raycaster.setFromCamera(this.mouse, this.camera);
		this.raycaster.far = 40
		let intersects = this.raycaster.intersectObjects(this.scene.getChildren());

		for (let i = 0; i < intersects.length; i++) {
			let isec = intersects[i].object

			if (!isec.userData.alphaID) {
				isec = this._searchParent(isec)
				if (!isec)
					continue
			}


			if (!isec.userData.alphaID)
				continue

			if (!isec.visible)
				continue

			if ((intersects[i].object.type === 'Mesh') && intersects[i].distance > 0.1) {
				intersects[i].object.userData = isec.userData //Bind correct alpha data to the object (as we search recursively)
				return intersects[i].object
			}
		}

		return null;
	}

	_filteredRaycast(e, shouldFilter = (obj) => {
		return false
	}) {
		this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
		this.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

		this.raycaster.setFromCamera(this.ZERO_VECTOR, this.camera);
		let intersects = this.raycaster.intersectObjects(this.scene.getChildren());

		for (let i = 0; i < intersects.length; i++) {
			if (shouldFilter(intersects[i])) {
				continue
			}
			return intersects[i]
		}
		return null
	}

	_updateManifest(key, value) {
		if (Array.isArray(this.manifest[key])) {
			return this._updateArray(key, value)
		} else if (typeof this.manifest[key] === 'object') {
			return this._updateObject(key, value)
		} else {
			console.error(`Unable to determine type for ${key}`, this.manifest[key])
		}
	}

	removeItem(category, key) {
		if (Array.isArray(this.manifest[category])) {
			return this._deleteArray(category, key)
		} else if (typeof this.manifest[category] === 'object') {
			return this._deleteObject(category, key)
		} else {
			console.error(`Unable to determine type for ${key}`, this.manifest[key])
		}
	}

	_updateArray(key, value) {

		console.log(`Updating: ${key} - array`, this.manifest[key])
		if (!value.id) {
			console.error("Value passed without id", value)
			return value
		}

		if (this.manifest[key] === null)
			this.manifest[key] = []

		for (let i = 0; i < this.manifest[key].length; i++) {
			if (this.manifest[key][i].id === this.active.value) {
				console.log(`Updating: ${key} - ${value.id} - array found: ${i}`)
				this.manifest[key][i] = Object.assign(this.manifest[key][i], value)
				return this.manifest[key][i]
			}
		}

		// console.log(`Adding: ${key} - not found in array: ${value.id}`, value)

		//Not found in array
		this.manifest[key].push(value)
		return value
	}

	_updateObject(key, value) {
		console.log(`Updating: ${key} - object`, {
			og: this.manifest[key],
			val: value,
			av: this.active.value
		})

		if (this.manifest[key] === null)
			this.manifest[key] = {}

		if (this.active.value !== undefined && this.active.value !== '') {
			this.manifest[key][this.active.value] = Object.assign(this.manifest[key][this.active.value], value)
			return this.manifest[key][this.active.value]
		}


		this.manifest[key] = Object.assign(this.manifest[key], value)
		return this.manifest[key]
	}

	_deleteArray(key, value) {
		console.log(`Deleting: ${key} - array - id: ${value}`, this.manifest[key])

		for (let i = 0; i < this.manifest[key].length; i++) {
			if (this.manifest[key][i].id === value) {
				console.log(`Deleting: ${key} - ${value} - array found: ${i}`)
				// delete this.manifest[key][i]

				this.manifest[key].splice(i, 1)
				return
			}
		}
	}

	_deleteObject(key, value) {
		console.log(`Deleting: ${key} - object`, {
			og: this.manifest[key],
			val: value,
		})

		if (value !== '')
			delete this.manifest[key][value]
		else
			delete this.manifest[key]

		return value
	}

	isMenuCommand(key, value, update = true) {
		if (value === 'back') {
			if (update)
				this.active = {menu: this.active.menu + "_list", data: {}};
			return true;
		}

		if (key.indexOf("_list") > -1) {
			if (update)
				this.active = {
					menu: key.split("_list")[0],
					value: value,
				}
			return true;
		}
		return false;
	}

	onManifestDelete(category, key) {
		console.log("onManifestDelete", {category, key})
		this.removeItem(category, key)

		console.log("Item removed", this.manifest)

		this.isMenuCommand(category, "back", true)
	}

	onManifestAdd(config, data) {
		console.log("editor::onManifestAdd", config, data)
		console.log("editor::onManifestAdd::config.data", config.data,)
		console.log("editor::onManifestAdd::data", data)
		switch (config.menu) {
			case "catalogue":
				this.addCatalogue(config.data)
				break;
			case "terrain":
				this.addTerrain(config.data)
				break;
			case "model":
			case "models":
				this.addModel(config.data)
				break;
			case "character":
				this.addCharacter(config.data)
				break;
			case "entity":
				this.addEntity(config.data)
				break;
			case "light":
				this.addLight(config.data)
				break;
			case "action":
				this.addAction(config.data)
				break;
			case "sound":
				this.addSound(config.data)
				break;
		}
	}

	addCatalogue(data = {}) {
		console.log("Adding Catalogue", data)
		// data.id = uuidv4();

		// this.manifest.terrain.push(data);
		this.active = {menu: 'terrain', item_id: data.id}
	}

	addTerrain(data = {}) {
		console.log("Adding Terrain", data)
		data.id = uuidv4();

		this.manifest.terrain.push(data);

		this.active = {menu: 'terrain', item_id: data.id}
	}

	addVoxel(data = {}, type) {
		console.log("Adding Voxel", data)
		const uuid = uuidv4();

		data = {
			id: uuid,
			name: uuid,
			position: data.position,
			rotation: data.rotation,
			scale: data.scale,
			scene: type,
			model: data,
		}

		this.manifest.voxels.push({
			position: [
				data.position.x,
				data.position.y,
				data.position.z,
			],
			texture_id: type,
		});

		// this.active = {menu: 'terrain', item_id: data.id}

		return data
	}

	addModel(data = {}) {
		console.log("Adding Model", data)
		// this.manifest.models.push(value);
		// this.manifest.models["NEW"] = data;


		this.active = {menu: 'models', item_id: "NEW"}
	}

	addCharacter(data = {}) {
		console.log("Adding Character", data)
		data.name = uuidv4();

		this.manifest.characters.push(data);
		this.active = {menu: 'character', item_id: data.name}
	}

	addEntity(data = {}) {
		console.log("Adding Entity", data)
		// data.id = uuidv4();

		// this.manifest.entities.push(data);

		this.active = {menu: 'entities', item_id: data.id}
	}

	addLight(data = {}) {
		console.log("Adding Light", data)
		data.id = uuidv4();

		// this.manifest.lighting.push(data);

		this.active = {menu: 'light', item_id: data.id}
	}

	addAction(data = {}) {
		console.log("Adding Action", data)
		data.id = uuidv4();

		this.manifest.actions["NEW"] = data;

		this.active = {menu: 'action', item_id: "NEW"}
	}

	addSound(data = {}) {
		console.log("Adding Sound", data)
		data.id = uuidv4();
		data.name = "";

		if (!this.manifest.sound.area_music)
			this.manifest.sound.area_music = [];

		this.manifest.sound.area_music.push(data);

		this.active = {menu: 'sound', item_id: data.id}
	}

}
