import * as THREE from "three";
import {ThreeRenderer} from "@alphabatem/ab-sdk-three";
import {ThreeNPC} from "@alphabatem/ab-sdk-three/src/entities/npc";
import {Editor} from "./editor";
import {LightController} from "./controller/light_controller";
import NPCController from "./controller/npc_controller";
import {ManifestController} from "./controller/manifest_controller";
import {ModelController} from "./controller/model_controller";
import {EntityController} from "./controller/entity_controller";
import {TerrainController} from "./controller/terrain_controller";
import {EnvironmentController} from "./controller/environment_controller";
import {CharacterController} from "./controller/character_controller";
import {AnimationsController} from "./controller/animations_controller";
import {VoxelBuilderController} from "./controller/voxel_builder/voxel_builder_controller";
import {VoxelsManager} from "./controller/voxel_builder/VoxelsManager";
import {VoxelTypes} from "./controller/voxel_builder/voxel_types";
import {FXAAShader} from "three/examples/jsm/shaders/FXAAShader";
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {OutlinePass} from "three/examples/jsm/postprocessing/OutlinePass";
import {SMAAPass} from "three/examples/jsm/postprocessing/SMAAPass";
import {recursiveUpdate} from "./recursiveUpdate";
import {rgbWrap} from "./rgbWrap";
import {TransformControls} from 'three/examples/jsm/controls/TransformControls.js';
import {ExecuteTransactionInteraction} from "./interactions/execute_txn";
import {TokenBalanceInteraction} from "./interactions/token_balance";
import {ShowAdvertisement} from "./interactions/show_advertisement";
import {DisplayController} from "./controller/display_controller";
import {MintCandyMachineInteraction} from "./interactions/mint_candy_machine";
import {MintNFTPrompt} from "./interactions/mint_token_prompt";
import {SignSolanaMessageInteraction} from "./interactions/sign_msg";

export class MetaverseInstance extends ThreeRenderer {

	dragControls = null
	audio = null; //Ambient audio

	manifest = null; //Metaverse manifest

	speechManager = null;

	started = false
	firstLoad = true

	debugMode = false;
	editor = null;

	outlinePass = null;
	bloomPass = null;

	isCity = false

	deactivateListeners = false;

	//Controllers
	baseController = new ManifestController(); //Gives access to base controller functions
	controllers = {
		//
	}

	animate() {
		super.animate();

		if (!this.outlinePass) {
			console.log("missing outline pass")
			return
		}

		// return //TODO Reenable once it only highlights entities with only valid interactions

		if (!this.editor)
			if (this.interactionHandler.lastHover !== null)
				this.outlinePass.selectedObjects = [this.search(this.interactionHandler.lastHover)] //TODO Optimize
			else
				this.outlinePass.selectedObjects = []
	}

	constructor(manifest, signer, debugMode = false, editor = false) {
		super()
		this.manifest = manifest
		this.debugMode = debugMode
		this.metaverseEdit = editor
		this.setSigner(signer)
		this.setupPostprocessing();

		this.setupControllers()
		this.registerInteractionHandlers()

		if (editor) {
			this.dragControls = new TransformControls(this.camera, this.renderer.domElement)
			this.scene.add(this.dragControls)
		}
	}

	onModelsLoaded = () => {
		super.onModelsLoaded();

		if (!this.firstLoad)
			return;

		this.firstLoad = false;

		this.entities(); //Load entities after models have loaded

		//Setup interactions
		this.actions();

		//Setup player/character sprites
		this.characters()

		//Register player & drone sprites
		this.setSprites()
	}


	playerAddedEvent = (e) => {
		console.log("playerAddedEvent", e)
	}
	playerRemovedEvent = (e) => {
		console.log("playerRemovedEvent", e)
	}

	onInteractionUIEvent = (e) => {
		console.warn("onInteractionUIEvent Handler not implemented", e)
	}

	setUIEventCallback(cb) {
		this.onInteractionUIEvent = cb
		this.getActionLogicHandler().setUIEventCallback(this.onInteractionUIEvent)
	}

	/**
	 * Register any interaction events & handlers for this level
	 * Crypto related handlers added at this level so not to pollute underlying sdk
	 */
	registerInteractionHandlers() {
		this.getInteractionHandler().addInteractionHandler(new MintCandyMachineInteraction(this.signer))
		this.getInteractionHandler().addInteractionHandler(new MintNFTPrompt())
		this.getInteractionHandler().addInteractionHandler(new ExecuteTransactionInteraction(this.signer))
		this.getInteractionHandler().addInteractionHandler(new SignSolanaMessageInteraction(this.signer))
		this.getInteractionHandler().addInteractionHandler(new TokenBalanceInteraction(this.signer))
		this.getInteractionHandler().addInteractionHandler(new ShowAdvertisement({scene: this.scene, advertisingManager: this.getManager("advertising")}))
	}

	setupControllers() {
		this.addManager("voxel", new VoxelsManager(this.scene, this.manifest.textures))
		this.voxelsManager = this.getManager("voxel")

		if (this.debugMode) {
			this.editor = new Editor(this.manifest, this.scene, this.camera)
		}

		this.controllers = {
			"npcs": new NPCController(this.getModelManager(), this.debugMode),
			"lighting": new LightController(this.getModelManager(), this.debugMode),
			"terrain": new TerrainController(this.getModelManager(), this.debugMode),
			"entities": new EntityController(this.getModelManager(), this.debugMode),
			"characters": new CharacterController(this.getModelManager(), this.debugMode),
			"models": new ModelController(this.getModelManager(), this.debugMode),
			"display": new DisplayController(this.getModelManager(), this, this.debugMode),
			"environment": new EnvironmentController(this.getModelManager(), this.debugMode),
			"animations": new AnimationsController(this.getModelManager(), this.debugMode),
			"voxel_builder": new VoxelBuilderController(this.voxelsManager, this, this.scene),
		}

		this.getVoxelBuilderController().onVoxelDelete = (target_voxel) => {
			const index = this.manifest.voxels.findIndex(({position}) => target_voxel.position.manhattanDistanceTo(new THREE.Vector3(position[0], position[1], position[2])) === 0)

			this.manifest.voxels.splice(index, 1)
		}
	}

	/**
	 * Enables loading from local files
	 */
	enableLocalLoad() {
		console.warn("LOCAL LOAD ENABLED - If no models are appearing try turning off `Local Load` in settings.")
		this.getModelManager().setLocalLoad(true)
	}

	addController(controller) {
		super.addController(controller);
	}

	start(localLoadEnabled = false) {
		console.log("Initializing manifest", this.manifest, localLoadEnabled)

		if (localLoadEnabled)
			this.enableLocalLoad()

		this.loadManifestScene()
	}

	getVoxelBuilderController() {
		return this.controllers["voxel_builder"]
	}

	onUpdate(controller, data, callback) {
		if (!this.controllers[controller]) {
			console.warn("Controller not found", controller)
			return
		}

		this.controllers[controller].update(this.scene, data, callback)
	}

	onPlayerChat(data) {
		super.onPlayerChat(data)
	}

	onPlayerAdd(data) {
		console.log('onPlayerAdd', data)
		super.onPlayerAdd(data);
		this.playerAddedEvent(data)
	}

	onPlayerRemove(data) {
		super.onPlayerRemove(data);
		this.playerRemovedEvent(data)
	}

	setupPostprocessing() {

		this._setupOutlinePass();

		// copyPass.renderToScreen = true
		// this.composer.addPass(effectFXAA);
		// this.composer.addPass(bokehPass);
		this.composer.addPass(this.outlinePass);
		this.composer.addPass(this._setupBloomPass());
		this.composer.addPass(this._setupSMAAPass());
		// this.composer.addPass(copyPass);
	}


	/**
	 * Configures the outline pass when users are hovering over interactables
	 * @private
	 */
	_setupOutlinePass() {
		this.outlinePass = new OutlinePass(new THREE.Vector2(this.getContainer().offsetWidth, this.getContainer().offsetHeight), this.scene.raw(), this.camera, [])
		this.outlinePass.renderToScreen = true;
		this.outlinePass.edgeThickness = 2;
		this.outlinePass.edgeStrength = 2;
		this.outlinePass.edgeGlow = 0.2;
		this.outlinePass.hiddenEdgeColor = new THREE.Color(1, 1, 1);
		this.outlinePass.visibleEdgeColor = new THREE.Color(1, 1, 1);
	}

	/**
	 * Configures SMAA postprocessing effect (sharpen)
	 * TODO User configuration & enablement
	 * @returns {SMAAPass}
	 * @private
	 */
	_setupSMAAPass() {
		let effectSMAA = new SMAAPass(window.innerWidth * this.renderer.getPixelRatio(), window.innerHeight * this.renderer.getPixelRatio());
		effectSMAA.renderToScreen = false
		return effectSMAA
	}

	/**
	 * Configures FXAA postprocessing effect (sharpen)
	 * TODO User configuration & enablement
	 * @returns {ShaderPass}
	 * @private
	 */
	_setupFXAAPass() {
		const pixelRatio = this.renderer.getPixelRatio();
		let effectFXAA = new ShaderPass(FXAAShader);
		effectFXAA.renderToScreen = false
		effectFXAA.material.uniforms['resolution'].value.x = 1 / (this.getContainer().offsetWidth * pixelRatio);
		effectFXAA.material.uniforms['resolution'].value.y = 1 / (this.getContainer().offsetHeight * pixelRatio);

		return effectFXAA
	}

	/**
	 * Configures bloom postprocessing effect
	 * TODO User configuration
	 * @returns {UnrealBloomPass}
	 * @private
	 */
	_setupBloomPass() {
		if (!this.manifest.display)
			this.manifest.display = {}
		if (!this.manifest.display.bloom)
			this.manifest.display.bloom = {
				threshold: 1,
				intensity: 0.4,
				radius: 0,
			}

		const env = this.manifest.display.bloom


		console.log("Configuring bloom", this.manifest.display.bloom)
		const pixelRatio = this.renderer.getPixelRatio();
		this.bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth * pixelRatio, window.innerHeight * pixelRatio), env.intensity, env.radius, env.threshold);
		// bloomPass.threshold = 0.8;
		this.bloomPass.threshold = env.threshold;
		this.bloomPass.strength = env.intensity;
		this.bloomPass.radius = env.radius;
		this.bloomPass.renderToScreen = false

		let params = {
			exposure: 0.1
		}

		this.gui.add(this.bloomPass, 'threshold', 0.1, 2).name('Threshold')
		this.gui.add(this.bloomPass, 'strength', 0.1, 2).name('strength')
		this.gui.add(this.bloomPass, 'radius', 0.1, 2).name('radius')

		this.gui.add(params, 'exposure', 0.1, 2).onChange((value) => {
			this.renderer.toneMappingExposure = Math.pow(value, 4.0);
		});

		return this.bloomPass
	}

	/**
	 * Load the scene
	 * @private
	 */
	async loadManifestScene() {
		await this.setEnvMap()

		//General helpers/utilities
		this.general();

		//Register environment
		this.environment()

		//Loads in models
		this.models();

		this.terrain();

		// this.animations()

		//Register required assets into the asset manager
		//Entities are loaded upon onAllAssetsLoaded to stop loading them before the scene is loaded
		// this.entities()

		this.npcs()

		this.voxels()

		//Register lighting
		this.lighting()

		//Register sound
		this.sound()

		//Register Events
		this.events()


		this.gui.closed = true
		this.gui.hide();
	}

	//BLOW


	general() {
		//Spawn points
		const sp = this.manifest.environment.spawn_point
		if (sp !== null) {
			console.log("Manifest spawn", sp)
			for (let i = 0; i < Object.keys(this.initialOffsets).length; i++) {
				this.initialOffsets[i] = {x: sp[0], y: sp[1], z: sp[2]}
			}

			console.log("Setting camera position: ", sp)
			this.camera.position.set(sp[0] - 1, sp[1] + 1, sp[2] - 1)
			this.camera.lookAt(new THREE.Vector3(sp[0], sp[1], sp[2]))

		}

		this.collisionHandler.setSpawnPositions(this.initialOffsets)
		this.collisionHandler.setSpeed(this.manifest.environment.player_speed || 10)
	}

	characters() {
		const env = this.manifest.characters;

		if (env === null) {
			console.warn("No characters loaded!")
			return
		}

		for (const char of env) {
			// console.log("character", char)

			if (char.model === "")
				continue;

			this.getModelManager().setModelAnimationBindings(char.model, char.animation_map)
			this.getManager("player").setDeviceModel(char.device_type, char.model) //Assign device type to the model
			this.getManager("player").setModelScale(char.model, char.scale)
			console.debug("Setting device model", char.device_type, char.model)

			this.getModelManager().addModel(char.model, this.manifest.models[char.model], (obj) => {
				console.log("Character model loaded", char.model, obj)
				if (obj.model)
					obj = obj.model

				obj.scale.set(char.scale.x, char.scale.y, char.scale.z)

				obj.castShadow = true
				obj.receiveShadow = true
				obj.userData.alphaID = char.id
				this.setShadows(obj)
			});
		}
	}

	models() {
		const env = this.manifest.models;

		if (env === null) {
			console.warn("No models loaded!")
			return
		}


		// console.log("Models", env)
		for (const model in env) {
			this.controllers["models"].add(this.scene, {id: model, scene: env[model]})
		}
	}

	entities() {
		const env = this.manifest.entities;

		if (env === null) {
			console.warn("No entities loaded!")
			return
		}

		for (const entity of env) {

			if (entity.data.model === "")
				continue;

			//Load into model manager & once loaded add to scene as a NPC
			// console.log("Loading entity", this.manifest.models[entity.data.model])
			this.getModelManager().addModel(entity.id, this.manifest.models[entity.data.model], (obj) => {
				this._onEntityLoaded(entity, obj)
			})
		}
	}

	_onEntityLoaded(entity, obj) {
		if (!obj.model) {
			obj.userData.alphaID = entity.id
			obj.userData.modelID = entity.data.model
			obj.castShadow = true
			obj.receiveShadow = true
			recursiveUpdate(obj, false, this.envMap)
		} else {
			obj.model.userData.alphaID = entity.id
			obj.model.userData.modelID = entity.data.model
			obj.model.castShadow = true
			obj.model.receiveShadow = true
			recursiveUpdate(obj.model, false, this.envMap)
		}

		const ent = new ThreeNPC(entity.id, entity.data.model)

		const ok = ent.add(this.getModelManager(), this.scene)
		if (!ok) {
			return //Unable to add model
		}

		ent.setPosition({
			x: entity.data.position[0],
			y: entity.data.position[1],
			z: entity.data.position[2]
		})
		ent.setRotation({
			x: entity.data.rotation[0],
			y: entity.data.rotation[1],
			z: entity.data.rotation[2]
		})
		ent.setScale({
			x: entity.data.scale[0],
			y: entity.data.scale[1],
			z: entity.data.scale[2]
		})
		ent.setOwner(this.manifest.owner)


		if (entity.modifiers) {
			this.baseController.applyModifiers(ent.obj, entity.modifiers)
		}

		// this.draggables.push(ent.obj)
		this.getEntityManager().addEntity(ent)

		if (entity.interactions) {
			this.getInteractionHandler().addInteractable(ent.obj, entity.interactions, false)
		}

		const loadedModel = this.getModelManager().getLoadedModel(entity.id)
		if (loadedModel.animations && loadedModel.animations.length > 0) {
			console.log("Adding entity animations: ", loadedModel.animations)
			this.getManager("animation").addAnimatable(ent.obj, loadedModel.animations)
		}


	}

	actions() {
		const env = this.manifest.actions
		if (env === null)
			return

		this.getActionLogicHandler().registerActions(env)
	}

	npcs() {
		const env = this.manifest.npcs;

		if (env === null) {
			console.warn("No npcs loaded!")
			return
		}

		console.log("NPCS", env)
		for (const npc of env) {
			// console.log("npc", npc)

			if (!npc.character_id)
				continue;

			//Load into model manager & once loaded add to scene as a NPC
			// console.log("Loading npc", this.manifest.models[npc.data.model])
			this.getModelManager().addModel(npc.id, this.manifest.models[npc.character_id], (obj) => {
				console.log("Loaded npc", obj)

				if (!obj.model) {
					obj.userData.alphaID = npc.id
					obj.userData.modelID = npc.character_id
					obj.castShadow = true
					obj.receiveShadow = true
					recursiveUpdate(obj, false, this.envMap)
				} else {
					obj.model.userData.alphaID = npc.id
					obj.model.userData.modelID = npc.character_id
					obj.model.castShadow = true
					obj.model.receiveShadow = true
					recursiveUpdate(obj.model, false, this.envMap)
				}

				const ent = new ThreeNPC(npc.id, npc.id)

				ent.add(this.getModelManager(), this.scene)
				ent.setPosition({
					x: npc.spawn_position[0],
					y: npc.spawn_position[1],
					z: npc.spawn_position[2]
				})
				ent.setRotation({
					x: npc.rotation[0],
					y: npc.rotation[1],
					z: npc.rotation[2]
				})
				ent.setScale({
					x: npc.scale[0],
					y: npc.scale[1],
					z: npc.scale[2]
				})
				// ent.setOwner(this.manifest.owner)

				// this.draggables.push(ent.obj)
				// this.getManager("npc").addNPC(ent)
				this.getNPCManager().addNPC(ent)

				if (npc.interactions) {
					this.getInteractionHandler().addInteractable(ent.obj, npc.interactions, false)
				}

				// if (obj.animations.length > 0) {
				// 	console.log("Adding npc animations: ", ent.animations)
				// 	this.getManager("animation").addAnimatable(ent, obj.animations)
				// }


			})
		}
	}

	/**
	 * Register lighting
	 */
	lighting() {
		const env = this.manifest.lighting

		if (env === null) {
			console.warn("No lighting loaded!")
			return
		}

		const shadowQuality = this.calculateShadowMap(this.manifest.environment.shadow_quality) || 512;
		// console.log("Shadow Quality", shadowQuality)

		this.controllers["lighting"].setQuality(shadowQuality)
		// console.log("Lights", env)
		for (const light of env) {
			this.controllers["lighting"].add(this.scene, light)
		}
	}

	calculateShadowMap(quality) {
		switch (quality) {
			case "low":
				return 512;
			default:
			case "medium":
				return 1024;
			case "high":
				return 2046;
			case "very_high":
				return 4096;
		}
	}

	terrain() {
		const env = this.manifest.terrain
		// console.log("Terrain", env)

		if (env === null) {
			console.warn("No terrain loaded!")

			const geometry = new THREE.PlaneGeometry(1000, 1000);
			const material = new THREE.MeshBasicMaterial({color: new THREE.Color(0, 0, 0), side: THREE.DoubleSide, reflectivity: 0});
			const plane = new THREE.Mesh(geometry, material);

			plane.name = "baseplane"
			this.scene.add(plane);

			return
		}

		for (const terrain of env) {
			this._addTerrain(env, terrain)
		}
	}

	_addTerrain(env, terrain) {
		if (terrain.scene === "")
			return;

		this.getModelManager().addModel(terrain.id, terrain.scene, (object) => {
			// console.log(`Adding to scene ${terrain.name}`, terrain, object)

			const _obj = object.model ? object.model.clone() : object.clone()

			const obj = _obj.clone()
			obj.userData.alphaID = terrain.id
			obj.castShadow = true
			obj.receiveShadow = true
			obj.scale.set(terrain.scale.x, terrain.scale.y, terrain.scale.z)
			obj.position.set(terrain.position.x, terrain.position.y, terrain.position.z)
			obj.rotation.set(terrain.rotation.x, terrain.rotation.y, terrain.rotation.z)
			obj.layers.enable(4);
			recursiveUpdate(obj, false, this.envMap)


			if (terrain.modifiers) {
				this.baseController.applyModifiers(obj, terrain.modifiers)
			}

			//Add to collider array
			this.getCollisionHandler().addTerrain(obj, terrain)

			this.scene.add(obj)

			// console.log(`Scene: ${terrain.name}`, this.scene)

			if (terrain.interactions) {
				this.getInteractionHandler().addInteractable(obj, terrain.interactions)
			}

			const loadedModel = this.getModelManager().getLoadedModel(terrain.id)
			if (loadedModel.animations && loadedModel.animations.length > 0) {
				console.log("Adding terrain animations: ", loadedModel.animations)
				this.getManager("animation").addAnimatable(obj, loadedModel.animations)
			}


		})
	}

	voxels() {
		if (!this.manifest.voxels) return

		let voxel
		let type
		for (let i = 0; i < this.manifest.voxels.length; i++) {
			const voxelInfo = this.manifest.voxels[i]

			if (type !== voxelInfo.texture_id) {
				voxel = this.voxelsManager.createVoxel(voxelInfo.texture_id)

				type = voxelInfo.texture_id
			} else {
				voxel = voxel.clone() //TODO this will never work?
			}

			voxel.position.set(
				voxelInfo.position[0],
				voxelInfo.position[1],
				voxelInfo.position[2],
			)

			this.onUpdate("voxel_builder", {
				id: voxel.uuid,
				name: voxel.uuid,
				position: {
					x: voxelInfo.position[0],
					y: voxelInfo.position[1],
					z: voxelInfo.position[2],
				},
				rotation: voxel.rotation,
				scale: voxel.scale,
				scene: voxelInfo.texture_id,
				model: voxel,
			})

			if (!this.metaverseEdit)
				this.getCollisionHandler().addTerrain(voxel.clone(), voxelInfo)
		}

		this.getVoxelBuilderController().selectVoxelType(VoxelTypes.GRASS)
	}

	environment() {
		const env = this.manifest.environment
		// const folder = this.gui.addFolder('Environment')

		//Register skybox

		//Background
		// this.scene.raw().background = new THREE.Color(rgbWrap(env.background));
		// folder.addColor(this.scene, 'background').name('Background Color')

		//Fog
		if (env.fog.start !== 0 && env.fog.end !== 0)
			this.scene.raw().fog = new THREE.Fog(rgbWrap(env.fog.color), env.fog.start, env.fog.end);
		// folder.add(this.scene.fog, 'near', 0, 2000).name('Fog Start')
		// folder.add(this.scene.fog, 'far', 0, 2000).name('Fog End')
		// folder.addColor(this.scene.fog, 'color').name('Fog Color')

		console.log("Setting renderer options", this.manifest.settings.name)
		if (this.manifest.settings.name === "Gallery Preview #001") {
			this.renderer.toneMappingExposure = 1.86
			this.bloomPass.threshold = 0.39
			this.bloomPass.strength = 0.39
			this.bloomPass.radius = 0
		}


		// this.skybox()
	}

	skybox() {
		const hourInDay = new Date().getHours()
		// let uris = [
		// 	'/assets/editor/skyboxes/g2/skybox_right_2x.png',
		// 	'/assets/editor/skyboxes/g2/skybox_left_2x.png',
		// 	'/assets/editor/skyboxes/g2/skybox_up_2x.png',
		// 	'/assets/editor/skyboxes/g2/skybox_down_2x.png',
		// 	'/assets/editor/skyboxes/g2/skybox_front_2x.png',
		// 	'/assets/editor/skyboxes/g2/skybox_back_2x.png',
		// ]
		let uris = [
			'/assets/editor/skyboxes/sunny_day_light/Daylight Box_Right.bmp',
			'/assets/editor/skyboxes/sunny_day_light/Daylight Box_Left.bmp',
			'/assets/editor/skyboxes/sunny_day_light/Daylight Box_Top.bmp',
			'/assets/editor/skyboxes/sunny_day_light/Daylight Box_Bottom.bmp',
			'/assets/editor/skyboxes/sunny_day_light/Daylight Box_Front.bmp',
			'/assets/editor/skyboxes/sunny_day_light/Daylight Box_Back.bmp',
		]

		// if (hourInDay > 11 && hourInDay < 18) {
		// 	uris = [
		// 		'/assets/editor/skyboxes/sunny_day/skybox_right.png',
		// 		'/assets/editor/skyboxes/sunny_day/skybox_left.png',
		// 		'/assets/editor/skyboxes/sunny_day/skybox_up.png',
		// 		'/assets/editor/skyboxes/sunny_day/skybox_down.png',
		// 		'/assets/editor/skyboxes/sunny_day/skybox_front.png',
		// 		'/assets/editor/skyboxes/sunny_day/skybox_back.png',
		// 	]
		// }

		const loader = new THREE.CubeTextureLoader();
		const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
		loader.load(uris, (texture) => {
			this.scene.raw().background = texture;
			// this.envMap = texture
			// this.scene.raw().environment = this.envMap;

			texture.encoding = THREE.sRGBEncoding
			this.envMap = pmremGenerator.fromCubemap(texture).texture;
			this.scene.raw().environment = this.envMap;
			pmremGenerator.dispose();
		});
		pmremGenerator.compileCubemapShader();
	}

	setEnvMap() {
		this.skybox()
		// return
	}

	sound() {

	}

	events() {

	}

	setSprites() {

	}


	// animations() {
	//     const env = [
	//         {
	//             name: "walk",
	//             model: "https://app.alphabatem.com/static/uploads/users/2wci94quHBAAVt1HC4T5SUerZR7699LMb8Ueh3CSVpTX/11d71cbdf783d68dfc2285b66f970126.glb"
	//         }
	//     ];
	//
	//     for (const i in env) {
	//         const animation = env[i]
	//         this.controllers["animations"].add(animation.name, {
	//             id: `animation_${animation.name}`,
	//             scene: animation.model
	//         })
	//     }
	// }

	loadScene() {
		//Stub out as we control from manifest
	}


	/**
	 *
	 */

	track = [];

	raycast(e) {
		const data = super.raycast(e);
		if (data.length === 0)
			return

		console.debug("MVERSE Click", data[0], data[0].point)
		this.track.push(data[0].point) //TODO This is for the NPC edit i think? why here???
	}

	onJoin(config) {
		console.log("MetaverseDemo#OnJoin", config)
		const is = this.initialOffsets[this.toDeviceTypeID(config.deviceType)];
		console.log("Initial spawn: ", is)
		this.camera.position.set(is.x, is.y, is.z)


		this.startMusic();
	}


	startMusic() {
		const env = this.manifest.sound
		if (!env.ambient_music || env.ambient_music === '' || env.ambient_music.url === '') return;

		// console.log("Setting audio: ", env)
		if (!this.audio) {
			this.audio = new Audio();
			this.audio.src = env.ambient_music.url;
			this.audio.volume = 0.1;
			this.audio.loop = true;
		}

		this.audio.play().then(() => {
			console.log("Music started")
		}).catch(e => {
			// console.log("Audio Error", e)
		})
	}

	onVoiceStart() {
		console.log("onVoiceStart")
		// this.speechManager.start()
	}

	onVoiceStop() {
		console.log("onVoiceStop")
		// this.speechManager.stop()
	}

	onKeyDown(e) {
		//
	}

	onKeyPress(e) {
		//
	}

	onStart(config) {
		if (this.started)
			return

		//Stop us from running twice
		this.started = true;

		// this.speechManager = new SpeechManager(this);

		console.log("MetaverseDemo#onStart loading demo experience", config)

		//TODO set from config
		// const walletAddr = Date.now().toString()
		const walletAddr = config.wallet_addr || ""

		console.log(`Connecting as device - ${walletAddr}: `, config)
		this.connectAsDevice(this.getDeviceHandler(config.deviceType), walletAddr, this.roomID, true)

		document.addEventListener("keypress", this.onKeyPress)
		document.addEventListener("keydown", this.onKeyDown)
	}

	onQuit() {
		console.log("MetaverseDemo::onQuit")
		this.destroy()

		if (this.audio) {
			try {
				this.audio.stop()
			} catch (e) {
				console.log("Audio failed to stop")
			}
		}

		document.removeEventListener("keypress", this.onKeyPress)
		document.removeEventListener("keydown", this.onKeyDown)
	}

	toDeviceTypeID(deviceType) {
		switch (deviceType.toUpperCase()) {
			default:
			case "3D":
				return 1
			case "AR":
				return 2
			case "OBSERVER":
				return 4
			case "3D3RD":
				return 6
		}
	}

	search(alphaID) {
		return this.baseController.searchScene(this.scene, alphaID)
	}

	/**
	 * Swap the running manifest
	 * @param manifest
	 * @param config
	 */
	swapManifest(manifest, config) {
		this.manifest = manifest
		this.start(); //Load metaverse assets
		// this.registerEvents();

		this.setRoom(config.roomID)
		this.onStart(config, true)
	}

	reset() {
		console.log("Metaverse::reset")
		this.isLoaded = false
		this.getConnectionManager().roomLeave(); //Leave the room

		//Clear existing scene
		this.scene.clear()
		const container = this.getContainer()
		const canvas = Array.from(container.children).findIndex(({localName}) => localName === 'canvas')
		container.removeChild(container.children[canvas])

		super.reset();
		this.setupPostprocessing()
		this.setupControllers()
		this.registerInteractionHandlers()
		// this.started = false
		this.firstLoad = true
	}

	setSettings(settings) {
		//TODO
	}

	getManifest() {
		return this.manifest
	}
}
