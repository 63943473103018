export function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b;
	});

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

export function rgbWrap(hex) {
	if (!hex)
		hex = "#FFFFFF"

	const rgb = hexToRgb(hex)
	if (!rgb) {
		console.error("Unable to rgbWrap hex: ", hex)
		return `rgb(255,255,255)`
	}

	return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
}