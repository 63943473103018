import {api, apiHelper} from "./api";

export class MetaverseDemo {
	prefix() {
		return "/demo/metaverses"
	}

	indexTop(page = 0, limit = 50, sort = 'ranking', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	index(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?user=${wallet_addr}&${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	manifest(uid) {
		return api.get(`/static/demos/manifests/${uid}.json`)
	}

	updateManifest(uid, manifest, signature) {
		return api.put(`${this.prefix()}/${uid}/manifest?code=${signature}`, {manifest: manifest})
	}
}

export default new MetaverseDemo()
