import {DefaultInteraction} from "@alphabatem/ab-sdk-three/src/interactions/interaction";

export class MintNFTPrompt extends DefaultInteraction {

	key() {
		return "mint_nft_prompt"
	}

	constructor() {
		super();
	}

	runInteraction(interactionData, metadata, onSuccess, onError) {
		this._emitEvent(interactionData.candy_machine_id)
		return true
	}


	onProximityExit(interactable) {
		window.dispatchEvent(new CustomEvent('interaction-out', {
			detail: {
				action_type: this.key(),
			}
		}))
	}


	onProximityEnter(interaction, interactable) {
		this._emitEvent(interaction.data.candy_machine_id)
	}

	_emitEvent(candyMachineID) {
		window.dispatchEvent(new CustomEvent('interaction-ui', {
			detail: {
				action_type: this.key(),
				data: {
					candy_machine_id: candyMachineID
				}
			}
		}))
	}
}