import {ManifestController} from "./manifest_controller";

export class EntityController extends ManifestController {


    add(scene, data, folder = null, callback = () => {}, addToScene = true) {

        console.log("EntityController.add()",data);

        const model = this.modelManager.getModel(data.data.model)
        if (!model) {
            console.error("EntityController.add()", "Model not found", data.data.model);
            return callback()
        }

        const obj = model.clone();
        obj.userData.alphaID = data.id
        obj.userData.modelID = data.data.model
        obj.position.set(data.data.position[0], data.data.position[1], data.data.position[2])
        obj.rotation.set(data.data.rotation[0], data.data.rotation[1], data.data.rotation[2])
        obj.scale.set(data.data.scale[0], data.data.scale[1], data.data.scale[2])

        if (data.modifiers) {
            this.applyModifiers(obj,data.modifiers)
        }

        if (addToScene) {
            // console.log("Adding object to scene: ", obj)
            scene.add(obj);
        }

        return callback()
    }

    //@deprecated
    toManifest(obj) {
        console.log("EntityController.toManifest()", obj)

        const data = {
            id: obj.userData.alphaID,
            data: {
                position: [obj.position.x, obj.position.y, obj.position.z],
                scale: [obj.scale.x, obj.scale.y, obj.scale.z],
                rotation: [obj.rotation.x, obj.rotation.y, obj.rotation.z],
            },
        }

        if (obj.userData.modelID) {
            data.data.model = obj.userData.modelID
        }


        return data
    }
}
