export function isModel(src) {
    if (src === '')
        return false

    const il = src?.toLowerCase()
    return il?.endsWith('glb') || il?.endsWith('gltf') || il?.endsWith('fbx') || il?.endsWith('obj')
}

export function isImage(src) {
    if (src === '')
        return false

    const il = src.toLowerCase()
    return il.endsWith('png') || il.endsWith('jpg') || il.endsWith('jpeg') || il.endsWith('gif')
}

export function isMusic(src) {
    if (src === '')
        return false

    const il = src.toLowerCase()
    return il.endsWith('mp4') || il.endsWith('ogg') || il.endsWith('wav')
}
