import {ManifestController} from "./manifest_controller";

export class TerrainController extends ManifestController {

    add(scene, data, folder = null, callback = () => {}, addToScene = true) {

        console.log("TerrainController.add()",data);

        this.modelManager.addModel(data.id, data.scene, (obj) => {

            let terrainObj;
            if (obj.model)
                terrainObj = obj.model.clone();
            else
                terrainObj = obj.clone();

            // console.log("Adding to scene", terrainObj)
            // this.getCollisionHandler().addTerrain(obj, terrain) //Dont build collisions on editor mode

            terrainObj.name = `terrain_${data.id}`
            terrainObj.castShadow = true
            terrainObj.receiveShadow = true
            terrainObj.userData.isTerrain = true
            terrainObj.userData.alphaID = data.id
            terrainObj.userData.name = data.name
            terrainObj.position.set(data.position.x, data.position.y, data.position.z)
            terrainObj.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z)
            terrainObj.scale.set(data.scale.x, data.scale.y, data.scale.z)

            if (data.modifiers) {
                this.applyModifiers(obj,data.modifiers)
            }

            if (addToScene) {
                console.log("Adding terrain object to scene: ", terrainObj)
                scene.add(terrainObj);
            }

            callback();
        })
    }

    toManifest(obj) {
        return {
            id: obj.userData.alphaID,
            name: obj.userData.name || obj.userData.alphaID,
            position: {
                x: obj.position.x,
                y: obj.position.y,
                z: obj.position.z
            },
            rotation: {
                x: obj.rotation.x,
                y: obj.rotation.y,
                z: obj.rotation.z
            },
            scale: {
                x: obj.scale.x,
                y: obj.scale.y,
                z: obj.scale.z
            }
        }
    }
}
