import {ManifestController} from "./manifest_controller";

export class DisplayController extends ManifestController {

	metaverse

	constructor(modelManager, metaverse, debugMode = false) {
		super(modelManager, debugMode);
		this.metaverse = metaverse
	}

	update(scene, object, callback) {
		console.log("DisplayController.update()", object);

		this.metaverse.bloomPass.threshold = object.bloom.threshold;
		this.metaverse.bloomPass.strength = object.bloom.intensity;
		this.metaverse.bloomPass.radius = object.bloom.radius;
		this.metaverse.renderer.toneMappingExposure = Math.pow(object.exposure, 4.0);
		callback();
	}

	toManifest(obj) {
		return {
			bloom: obj.bloom,
			exposure: obj.exposure,
		}
	}
}