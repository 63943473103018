import {DefaultInteraction} from "@alphabatem/ab-sdk-three/src/interactions/interaction";
import {Metaplex, walletAdapterIdentity} from "@metaplex-foundation/js";
import {PublicKey} from "@solana/web3.js";

export class MintCandyMachineInteraction extends DefaultInteraction {

	_signer

	key() {
		return "mint_candy_machine"
	}

	constructor(signer) {
		super();
		this._signer = signer
	}

	async runInteraction(interactionData, metadata, onSuccess, onError) {
		console.log("MintCandyMachineInteraction", JSON.stringify(interactionData), metadata)

		const metaplex = new Metaplex(this._signer.getConnection())

		const cm = await metaplex.candyMachinesV2().findByAddress({
			address: new PublicKey(interactionData.candy_machine_id)
		})

		metaplex.identity().setDriver(this._signer);
		metaplex.candyMachinesV2().mint({
			candyMachine: cm,
			payerToken: new PublicKey(this._signer.getWalletAddr())
		}, {
			payer: this._signer, commitment: "confirmed"
		}).then((r) => {
			console.log("R", r)
			onSuccess(r)
		}).catch((e) => {
			console.log(`MintCandyMachineInteraction`, e)
			onError(e)
		})

		return true
	}
}