import {ManifestController} from "./manifest_controller";

export class CharacterController extends ManifestController {


    add(scene, data, folder = null, callback = () => {
    }, addToScene = true) {

        console.log("CharacterController.add()", data);
        //Nothing to do in the scene

        return callback()
    }

    //@deprecated
    toManifest(obj) {
        return {
            id: obj.id, name: obj.id, device_type: "3D3RD",
            position: {
                x: obj.position.x,
                y: obj.position.y,
                z: obj.position.z
            },
            rotation: {
                x: obj.rotation.x,
                y: obj.rotation.y,
                z: obj.rotation.z
            },
            scale: {
                x: obj.scale.x,
                y: obj.scale.y,
                z: obj.scale.z
            }
        }
    }
}