import {DefaultInteraction} from "@alphabatem/ab-sdk-three/src/interactions/interaction";

export class AdvertScreen {
	advert_screen_id //Unique screen advert id
	obj = null
	duration = 1000 * 60
	media = [];
	mediaIdx = 0;
	last_updated
	mesh_names = ["advert"];

	constructor(advert_screen_id, obj, media = [], duration = 60000) {
		this.advert_screen_id = advert_screen_id
		this.obj = obj
		this.duration = duration
		this.media = media
	}

	setMeshNames(meshNames = []) {
		this.mesh_names = meshNames
	}

	nextMediaItem() {
		this.last_updated = Date.now()
		this.mediaIdx++
		if (this.mediaIdx >= this.media.length)
			this.mediaIdx = 0

		return this.media[this.mediaIdx]
	}

	setMedia(media = []) {
		this.media = media
	}

	shouldUpdate() {
		return this.last_updated + this.duration < Date.now()
	}

	getMeshNames() {
		return this.mesh_names
	}

	reset() {
		//TODO Reset?
	}
}

export class ShowAdvertisement extends DefaultInteraction {

	scene = null

	advertScreens = {};

	adManager

	constructor(props) {
		super(props);

		this.scene = props.scene
		this.adManager = props.advertisingManager
	}


	key() {
		return "show_advertisement"
	}

	onAdd(obj, interaction) {
		const adScreen = new AdvertScreen(interaction.data.advert_screen_id, obj, interaction.data.media, interaction.data.duration)
		if (interaction.data.mesh_names)
			adScreen.setMeshNames(interaction.data.mesh_names)

		this._addAdvertScreen(obj.userData.alphaID, adScreen)
	}

	interact(interactionData, metadata, onSuccess, onError) {
		console.log(`Interaction::${this.key()} - Run`, interactionData, metadata)

		const refObj = this.scene.get(interactionData.target_id)
		const adScreen = new AdvertScreen(interactionData.advert_screen_id, refObj, interactionData.media, interactionData.duration)

		if (interactionData.mesh_names)
			adScreen.setMeshNames(interactionData.mesh_names)

		this._addAdvertScreen(refObj.userData.alphaID, adScreen)

		return true
	}

	_updateAdvertScreen(adScreen) {
		const nextMedia =  adScreen.nextMediaItem()
		// console.log("Updating advert screen", adScreen, nextMedia)
		this.adManager.addBillboardImage(adScreen.obj, nextMedia, adScreen.getMeshNames())
	}

	_removeAdvertScreen(alphaID) {
		if (!this.advertScreens[alphaID])
			return

		this.advertScreens[alphaID].reset()
		delete this.advertScreens[alphaID]
	}

	_addAdvertScreen(alphaID, adScreen) {
		if (!this.advertScreens[alphaID]) {
			// console.warn("Advert screen already registered", alphaID)
			this.advertScreens[alphaID] = adScreen
		}
		this.adManager.addBillboardImage(adScreen.obj, adScreen.nextMediaItem(), adScreen.getMeshNames())
	}

	onProximityEnter(interaction, interactable) {
		this.runInteraction(interaction.data, {}, () => {}, (e) => {
			console.error("Interaction::${this.key()} - onProximityEnter - Err", e)
		})
	}

	onProximityExit(interactable) {
		this._removeAdvertScreen(interactable.userData.alphaID)
	}

	/**
	 * Check for any screens that need updating
	 *
	 * @param interactions
	 */
	onTick(interactions = []) {
		for (let alphaID in this.advertScreens) {
			if (!this.advertScreens[alphaID].shouldUpdate())
				return

			this._updateAdvertScreen(this.advertScreens[alphaID])
		}
	}
}