import * as THREE from "three";
import {ManifestController} from "./manifest_controller";

export default class NPCController extends ManifestController {

	constructor(modelManager, debugMode = false) {
		super(modelManager, debugMode)
	}

    add(scene, data, folder = null, callback = () => {}, addToScene = true) {

        console.log("NPCController.add()",data);

        const model = this.modelManager.getModel(data.character_id)
        
        if (!model) {
            console.warning("NPCController.add()", "Model not found", data.character_id);
            return
        }

        const obj = model.clone();
        obj.userData.alphaID = data.id
        obj.userData.modelID = data.character_id
        obj.position.set(data.spawn_position[0], data.spawn_position[1], data.spawn_position[2])
        obj.rotation.set(data.rotation[0], data.rotation[1], data.rotation[2])
        obj.scale.set(data.scale[0], data.scale[1], data.scale[2])

        if (addToScene) {
            console.log("Adding object to scene: ", obj)
            scene.add(obj);
        }

        return callback()
    }

	toManifest(obj) {
        return {
            id: obj.userData.alphaID,
            character_id: obj.userData.modelID,
            spawn_position: [
                obj.position.x,
                obj.position.y,
                obj.position.z
            ],
            rotation: [
                obj.rotation.x,
                obj.rotation.y,
                obj.rotation.z
            ],
            scale: [
                obj.scale.x,
                obj.scale.y,
                obj.scale.z
            ]
        }
	}
}
