import {api} from "./api";

export class Auth {
	prefix() {
		return "auth"
	}

	loginWeb2(email, password) {
		return api.post(`${this.prefix()}/web2/login`, {
			email: email,
			password: password
		})
	}

	createWeb2(user) {
		return api.post(`${this.prefix()}/web2/signup`, {
			email: user.email,
			name: user.name,
			password: user.password,
			password_confirmed: user.password_confirmed
		})
	}

	loginQR(id, passcode) {
		return api.post(`${this.prefix()}/qr/login`, {
			email: id,
			password: passcode
		})
	}

	createQR(qrID) {
		return api.post(`${this.prefix()}/qr/signup/${qrID}`, {})
	}

	leaveGuild() {
		return api.put(`${this.prefix()}/users/profile/guild/leave`)
	}

	//Accepts Web3ProfileUpdate
	update(uid, obj) {
		///auth/users/profile
		return api.put(`${this.prefix()}/users/profile`, obj)
	}


	connect(walletAddr) {
		return api.post(`${this.prefix()}/connect/${walletAddr}`)
	}


	nonce(walletAddr) {
		return api.get(`${this.prefix()}/nonce/${walletAddr}`)
	}

	/**
	 * Temp endpoint
	 * TODO move to WebRTC
	 * @param formData
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	uploadUserFile(formData) {
		return api.post(`/auth/users/file`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
	}
}

export default new Auth()