import {api, apiHelper} from "./api";

export class Room {
	prefix() {
		return "rooms"
	}

	index(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	wsDetail(uid) {
		return api.get(`${this.prefix()}/${uid}/ws_detail`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	update(uid, obj) {
		return api.put(`${this.prefix()}/${uid}`, obj)
	}

	delete(uid) {
		return api.delete(`${this.prefix()}/${uid}`)
	}
}

export default new Room()