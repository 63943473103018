import * as THREE from "three";

import {Cube, VoxelTypes} from "./voxel_types";

export class VoxelsManager {
	cubeSize = 1

	instanceMap = {};
	instanceCount = {};

	perEntityCount = 100;

	baseTextures = {}
	baseCubes = {}

	scene

	constructor(scene, custom_textures) {
		this.scene = scene
		this.cubeSize = 1
		this.custom_textures = custom_textures

		const loader = new THREE.TextureLoader()

		this.grassTopTexture = loader.load('./assets/voxel_builder/uv_textures/grass_block_top.png')
		this.grassTopTexture.encoding = THREE.sRGBEncoding
		this.topGrassMesh = new THREE.MeshStandardMaterial({
			map: this.grassTopTexture
		})

		this.grassBottomTexture = loader.load('./assets/voxel_builder/uv_textures/grass_block_bottom.png')
		this.grassBottomTexture.encoding = THREE.sRGBEncoding
		this.bottomGrassMesh = new THREE.MeshStandardMaterial({
			map: this.grassBottomTexture
		})

		this.grassSideTexture = loader.load('./assets/voxel_builder/uv_textures/grass_block_side.png')
		this.grassSideTexture.encoding = THREE.sRGBEncoding
		this.sideGrassMesh = new THREE.MeshStandardMaterial({
			map: this.grassSideTexture
		})

		this.dirtTexture = loader.load('./assets/voxel_builder/uv_textures/grass_block_bottom.png')
		this.dirtTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.DIRT] = new THREE.MeshStandardMaterial({
			map: this.dirtTexture
		})

		this.ironTexture = loader.load('./assets/voxel_builder/uv_textures/iron.png')
		this.ironTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.IRON] = new THREE.MeshStandardMaterial({
			map: this.ironTexture
		})

		this.redstoneTexture = loader.load('./assets/voxel_builder/uv_textures/redstone.jpeg')
		this.redstoneTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.REDSTONE] = new THREE.MeshStandardMaterial({
			map: this.redstoneTexture
		})

		this.brickTexture = loader.load('./assets/voxel_builder/uv_textures/nether-brick.png')
		this.brickTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.NETHER_BRICK] = new THREE.MeshStandardMaterial({
			map: this.brickTexture
		})

		this.redSanstoneTexture = loader.load('./assets/voxel_builder/uv_textures/red-sandstone.png')
		this.redSanstoneTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.RED_SANDSTONE] = new THREE.MeshStandardMaterial({
			map: this.redSanstoneTexture
		})

		this.waterTexture = loader.load('./assets/voxel_builder/uv_textures/water.png')
		this.waterTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.WATER] = new THREE.MeshStandardMaterial({
			map: this.waterTexture
		})

		this.lavaTexture = loader.load('./assets/voxel_builder/uv_textures/lava.png')
		this.lavaTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.LAVA] = new THREE.MeshStandardMaterial({
			map: this.lavaTexture
		})

		this.snowTexture = loader.load('./assets/voxel_builder/uv_textures/snow.png')
		this.snowTexture.encoding = THREE.sRGBEncoding
		this.baseTextures[VoxelTypes.SNOW] = new THREE.MeshStandardMaterial({
			map: this.snowTexture
		})
	}

	_preloadInstanceCubes() {

	}

	createVoxel(type) {
		console.log('createVoxel::type', type)

		switch (type) {
			case VoxelTypes.GRASS:
				return this.createGrass()
			case VoxelTypes.DIRT:
			case VoxelTypes.IRON:
			case VoxelTypes.REDSTONE:
			case VoxelTypes.NETHER_BRICK:
			case VoxelTypes.RED_SANDSTONE:
			case VoxelTypes.WATER:
			case VoxelTypes.LAVA:
			case VoxelTypes.SNOW:
				return this.createTexturedCube(type)
			default:
				return this.createCustomCube(type)
		}
	}

	createCustomCube(urlIndex) {
		const texture = new THREE.TextureLoader().load(this.custom_textures[urlIndex])
		texture.encoding = THREE.sRGBEncoding
		const mesh = new THREE.MeshStandardMaterial({
			map: texture
		})

		return this.unitextureCube(mesh)
	}

	createBaseCube() {
		// top
		const pyGeometry = new THREE.PlaneGeometry(this.cubeSize, this.cubeSize);
		pyGeometry.rotateX(-Math.PI / 2);
		pyGeometry.translate(0, this.cubeSize / 2, 0);

		// bottom
		const nyGeometry = new THREE.PlaneGeometry(this.cubeSize, this.cubeSize);
		nyGeometry.rotateX(Math.PI / 2);
		nyGeometry.translate(0, -this.cubeSize / 2, 0);

		// sides
		const pxGeometry = new THREE.PlaneGeometry(this.cubeSize, this.cubeSize);
		pxGeometry.rotateY(Math.PI / 2);
		pxGeometry.translate(this.cubeSize / 2, 0, 0);

		const nxGeometry = new THREE.PlaneGeometry(this.cubeSize, this.cubeSize);
		nxGeometry.rotateY(-Math.PI / 2);
		nxGeometry.translate(-this.cubeSize / 2, 0, 0);

		const pzGeometry = new THREE.PlaneGeometry(this.cubeSize, this.cubeSize);
		pzGeometry.translate(0, 0, this.cubeSize / 2);

		const nzGeometry = new THREE.PlaneGeometry(this.cubeSize, this.cubeSize);
		nzGeometry.rotateY(Math.PI);
		nzGeometry.translate(0, 0, -this.cubeSize / 2);

		return [
			pyGeometry, nyGeometry,
			pxGeometry, nxGeometry,
			pzGeometry, nzGeometry,
		]
	}

	createGrass() {
		const geometries = this.createBaseCube()

		return new THREE.Group().add(
			new THREE.Mesh(geometries[Cube.TOP], this.topGrassMesh), new THREE.Mesh(geometries[Cube.BOTTOM], this.bottomGrassMesh),
			new THREE.Mesh(geometries[Cube.SIDE_1], this.sideGrassMesh), new THREE.Mesh(geometries[Cube.SIDE_2], this.sideGrassMesh),
			new THREE.Mesh(geometries[Cube.SIDE_3], this.sideGrassMesh), new THREE.Mesh(geometries[Cube.SIDE_4], this.sideGrassMesh)
		)
	}

	unitextureCube(texture) {
		const geometries = this.createBaseCube()

		return new THREE.Group().add(
			new THREE.Mesh(geometries[Cube.TOP], texture), new THREE.Mesh(geometries[Cube.BOTTOM], texture),
			new THREE.Mesh(geometries[Cube.SIDE_1], texture), new THREE.Mesh(geometries[Cube.SIDE_2], texture),
			new THREE.Mesh(geometries[Cube.SIDE_3], texture), new THREE.Mesh(geometries[Cube.SIDE_4], texture)
		)
	}

	createTexturedCube(type) {
		console.log("Creating textured cube", type, this.baseCubes[type])
		if (this.baseCubes[type])
			return this.baseCubes[type]

		console.log(`Creating cube: ${type}`, this.baseTextures[type])
		this.baseCubes[type] = this.unitextureCube(this.baseTextures[type])
		return this.baseCubes[type]
	}


	createInstancedCube(type) {
		if (!this.baseCubes[type]) {
			const imap = new THREE.InstancedMesh(new THREE.PlaneGeometry(this.cubeSize, this.cubeSize), this.baseTextures[type], this.perEntityCount)
			imap.castShadow = false
			imap.receiveShadow = true
			this.baseCubes[type] = imap
			this.scene.add(imap)
		}

		return this.baseCubes[type]
	}
}
