import {api, apiHelper} from "./api";

export class QRCodeAPI {
	prefix() {
		return "qr_codes"
	}

	index(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`/auth/users/${wallet_addr}/${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexMetaverse(metaverse, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`/metaverses/${metaverse}/${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	scan(uid) {
		return api.get(`${this.prefix()}/${uid}/scan`)
	}

	create(obj,signature) {
		return api.post(`${this.prefix()}`, {
			qr_code: obj,
			signed: signature
		})
	}

	update(uid, obj, signature) {
		return api.put(`${this.prefix()}/${uid}`, {qr_code: obj, signed: signature})
	}

	sync(uid) {
		return api.post(`${this.prefix()}/${uid}`, {})
	}
}

export default new QRCodeAPI()