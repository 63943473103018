export function recursiveUpdate(obj, shadows = false, envMap = null) {
	obj.traverse((object) => {
		if (object.material) {
		    // if (object.material.metalness === 1) {
		    //     object.material.metalness = object.material.metalness / 2
		    // }
		}; //todo check why

		if (object.type === "SkinnedMesh") {
			object.pose()
		}

		if (object.type === "Group") {
			for (const child of object.children) {
				recursiveUpdate(child)
			}
		}
		//
		if (object.isMesh) {
			if (envMap !== null) {
				object.material.envMap = envMap;
				object.material.needsUpdate = true
			}

			object.layers.enable(4)
			object.userData.isTerrain = true;
			if (obj.userData.alphaID)
				object.userData.alphaID = obj.userData.alphaID

			if (shadows) {
				object.castShadow = true
				object.receiveShadow = true
			}

			object.geometry.computeVertexNormals();
		}
	})

	return obj
}