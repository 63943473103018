import {ManifestInterface} from "./manifest_interface";

export class ManifestController extends ManifestInterface {

	modelManager;

	constructor(modelManager, debugMode = false) {
		super();

		this.modelManager = modelManager
		this.debugMode = debugMode;
	}

	update(scene, object, callback) {
		console.log("ManifestController::update - Updating", object)
		const obj = this.searchScene(scene, object.id)
		if (obj === null) {
			console.warn("ManifestController::update - Unable to find object", object)
			// return
		}else if(obj.name === "Scene") {
			console.warn("ManifestController::update - Search returned scene", obj)
			return
		} else {
			console.log("ManifestController::update - Removing obj", obj)

			if (obj.parent.type === "Group" && obj.parent.name !== "Scene")
				scene.remove(obj.parent)
			else
				scene.remove(obj)

			console.log("ManifestController::update - Removed from scene", scene)
		}

		this.add(scene, object, null, callback, true)
	}

	hexToRgb(hex) {
		// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b;
		});

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	rgbWrap(hex) {
		if (!hex)
			hex = "#FFFFFF"

		const rgb = this.hexToRgb(hex)
		return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
	}

	searchScene(scene, alphaID, recursive = false) {
		// console.log("Searching scene for: ", alphaID)
		let obj = this._searchScene(scene, alphaID, recursive)

		if (obj.length > 0)
			return obj[0]

		return null
	}

	_searchScene(scene, alphaID, recursive = false) {
		const objs = [];

		if (!recursive) {
			const children = scene.getChildren()
			for (let i = 0, l = children.length; i < l; i++) {
				const child = children[i];
				if (child.userData.alphaID === alphaID) {
					return [child]
				}
			}
			return [];
		}

		scene.raw().traverse((o) => {
			if (o.userData && o.userData.alphaID) {
				if (o.userData.alphaID === alphaID) {
					// console.log(`OBJ: ${o.uuid} - ${o.name} - ${o.userData.alphaID} - ${alphaID}`, o)
					objs.push(o)
					return false;
				}
			}
		}, recursive)
		return objs;
	}

	applyModifiers(obj, modifiers) {
		for (let i = 0, l = modifiers.length; i < l; i++) {
			const mod = modifiers[i]
			if (!obj[mod.property]) {
				console.warn(`Missing modifier property ${mod.property} on obj`, obj)
				continue
			}
			obj[mod.property] = mod.value
		}

	}

	// recursiveUpdate(obj) {
	//     obj.traverse((object) => {
	//         // if (object.material) {
	//         //     if (object.material.metalness > 0) {
	//         //         object.material.metalness = object.material.metalness / 2
	//         //     }
	//         // }; //todo check why
	//
	//         if (object.type === "Group") {
	//             for (const child of object.children) {
	//                 recursiveUpdate(child)
	//             }
	//         }
	//         //
	//         if (object.isMesh) {
	//             if (this.envMap !== null)
	//                 object.envMap = this.envMap;
	//
	//             object.userData.isTerrain = true;
	//             object.castShadow = true
	//             object.receiveShadow = true
	//             object.geometry.computeVertexNormals();
	//         }
	//     })
	// }
}
