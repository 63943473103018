import * as web3 from "@solana/web3.js";
import axios from "axios";
import {Metadata} from "@metaplex-foundation/mpl-token-metadata";
import {getParsedNftAccountsByOwner} from "@nfteyez/sol-rayz";

/**
 * Utility class helping with Solana NFT data collation for users
 */
export class UserAssets {

	loading = false

	cacheHash = "0x01"

	loadDangerousNFTs = false

	loadFileMetadata = false

	//Land owned by user
	ownedLand = [];

	//NFT assets owned by the user (all excluding land)
	ownedNFT = [];

	landAuthority = "42GozpYPbQ7oixVZwW84EMHTFA7KwGYEaxRRDGkDRTBF"

	constructor(url, config) {
		this.connection = new web3.Connection(
			url, config,
		);
	}

	getOwnedLand() {
		return this.ownedLand;
	}

	getOwnedNFTs() {
		return this.ownedNFT;
	}

	getBalance(tokenAddr) {
		return this.connection.getBalance(new web3.PublicKey(tokenAddr), "confirmed")
	}

	getTokenInfo(tokenAddr) {
		return this.connection.getParsedAccountInfo(new web3.PublicKey(tokenAddr))
	}

	setLoadDangerousNFTs(toggle) {
		this.loadDangerousNFTs = toggle
	}

	async getTokenOwner(tokenAddr) {
		const largestAccounts = await this.connection.getTokenLargestAccounts(new web3.PublicKey(tokenAddr));
		const largestAccountInfo = await this.connection.getParsedAccountInfo(largestAccounts.value[0].address);

		return largestAccountInfo.value.data.parsed.info.owner
	}

	reloadAccountInfo(walletAddr, cb) {
		this.reset()
		this._fetchOwnerAccounts(walletAddr, cb)
	}

	reset() {
		this.ownedNFT = []
		this.ownedLand = []
	}

	getAccountInfo(walletAddr, cb = null) {
		console.log("getAccountInfo", walletAddr)
		if (walletAddr === undefined || walletAddr === "") {
			console.error("UserAssets - Wallet address is undefined or empty");
			return;
		}

		//Return cached accounts if we have them
		const nfts = this.getCachedAccounts(walletAddr)
		if (nfts && nfts.items.length > 0 && nfts.last_updated && (Date.now() - nfts.last_updated) < (1000 * 60 * 60)) {
			console.log("Returning Cache: Last Updated", nfts.last_updated, (Date.now() - nfts.last_updated) < (1000 * 60 * 60))
			this.reset()
			this._registerNFTAccounts(nfts.items)
			if (cb !== null)
				cb()
			return;
		}

		if (this.loading === walletAddr)
			return; //Already loading

		this.loading = walletAddr
		console.log("Cache Miss: Fetching:", walletAddr)
		this._fetchOwnerAccounts(walletAddr, cb)
	}

	_fetchOwnerAccounts(walletAddr, cb = null) {
		try {
			getParsedNftAccountsByOwner({
				publicAddress: walletAddr,
				connection: this.connection,
				serialization: true,
			}).then(nfts => {
				this._cacheNFTAccounts(walletAddr, nfts)
				this._registerNFTAccounts(nfts)
			}).finally(() => {
				this.loading = false
				if (cb !== null)
					cb()
			})
		} catch (e) {
			console.log("getAccountInfo", e)
		}
	}

	_registerNFTAccounts(nfts = []) {
		console.log("Registering NFTs", nfts.length, nfts)
		nfts.forEach((nft) => {
			const asset = {
				id: nft.mint,
				mint: nft.mint,
				name: nft.data.name,
				symbol: nft.data.symbol,
				uri: nft.data.uri,
				metadata: {},
				creators: nft.data.creators,
				image: "",
				authority: nft.updateAuthority,
				collection: nft.collection ? nft.collection.key : ''
			}

			this._registerNFTAsset(asset)
		})
	}

	getAllMetadata(tokens) {
		// console.log("Getting all metadata", tokens)
		return this.connection.getMultipleAccountsInfo(tokens, "confirmed")
	}

	/**
	 * Gets a single tokens metadata
	 * When using public rpc this should be avoided (use getInfos instead)
	 * @param token
	 * @private
	 */
	_getTokenInfo(token) {
		// console.log("Token", {
		// 	tokenPK: token.pubkey.toString(),
		// 	ownerPK: token.account.owner.toString(),
		// 	mintPk: token.account.data.parsed.info.mint
		// })

		//Not an NFT if > 1
		if (token.account.data.parsed.info.uiAmount > 1)
			return;

		this.getMetadata(token.account.data.parsed.info.mint).then(resp => {
			const nft = resp.data;
			// console.log("NFT Item", nft)
			this.registerNFTAsset(token, nft)

		}).catch(e => {
			console.error(`getLandNFTs ${token.account.data.parsed.info.mint}`, e);
		});
	}

	getTokenMint(tokenAccountAddr, cb) {
		this.connection.getParsedAccountInfo(new web3.PublicKey(tokenAccountAddr)).then((resp) => {
			cb(resp.value.data.parsed.info.mint)
		})
	}

	getTokenMetadata(tokenMintAddr) {
		return this.getMetadata(tokenMintAddr)
	}

	registerNFTAsset(token, nft) {
		const asset = {
			id: token.pubkey.toString(),
			mint: token.account.data.parsed.info.mint,
			name: nft.data.name,
			symbol: nft.data.symbol,
			uri: nft.data.uri,
			metadata: {},
			image: "",
			collection: nft.collection ? nft.collection.key : ''
		}

		return this._registerNFTAsset(asset)
	}

	_registerNFTAsset(asset) {
		if (asset.uri === '') {
			console.debug("no URI", asset)
			return;
		}

		if (!this.isValidUri(asset)) {
			return;
		}

		const cache = this.getCached(asset.id);
		if (cache !== null && this.isLocalCacheValid(cache)) {
			// console.log("Using cached metadata", asset.id)
			this.loading = false;
			asset.metadata = cache;
			asset.image = cache.image;

			this._registerAsset(asset);
			return;
		}

		if (!this.loadFileMetadata) {
			this.loading = false;
			asset.image = `https://api.degencdn.com/v1/nfts/${asset.id}/image.jpg`

			this._registerAsset(asset);
			return;
		}

		this.getMetadataFile(asset.uri).then(resp => {
			this.loading = false;

			asset.metadata = resp.data;
			asset.image = resp.data.image;

			if (asset.image.substr(-6).indexOf(".") === -1 && asset.uri.indexOf("ipfs") === 0) {
				console.log("Ext not detected", asset.image.substr(-4))
				asset.image = asset.image + ".jpg"
			}

			this.setCache(asset.id, resp.data)
			this._registerAsset(asset)

		}).catch(e => {
			console.error(`getMetadataFile ${asset.uri}`, e);
		});
	}

	_registerAsset(asset) {
		// console.log("Registering NFT Asset", asset)

		this.ownedNFT.push(asset)
		this._registerLandTile(asset) //Register as AB Land (validated in func)
	}


	isValidUri(asset) {
		// console.log("Asset", asset)

		const url = new URL(asset.uri);
		switch (url.hostname) {
			case "ipfs.io":
			case "ipfs.dweb.link":
			case "arweave.net":
			case "nftstorage.link":
			case "degenfatcats.s3.amazonaws.com":
			case "shdw-drive.genesysgo.net":
			case "testlaunchmynft.mypinata.cloud":
				return true;
			default:
				// console.debug("Dangerous NFT URI Detected, ignoring", asset.uri)
				asset.isDangerous = true;
				return this.loadDangerousNFTs;
		}
	}

	isABLand(nft) {
		if (nft.collection === "4ssgx1C7UoVSc8cc8AeKYnBg24xWk3j5KdRJ6mJSpkgJ" || (nft.authority === this.landAuthority && nft.symbol === "ABML"))
			return true

		for (let i = 0; i < nft.creators.length; i++) {
			if (!nft.creators[i].verified) continue
			if (nft.creators[i].address === this.landAuthority)
				return true
		}

		return false
	}

	/**
	 * Register a AB land tile as owned by the user
	 *
	 * @param land
	 * @private
	 */
	_registerLandTile(land) {
		if (!this.isABLand(land)) {
			return
		}

		// console.log("Adding land", land)
		this.ownedLand.push(Object.assign({}, land))
	}

	getMetadataFile(file) {
		return axios.get(file)
	}

	async getMetadata(acc) { //mint Address : String (Public Key)
		// console.log("Getting metadata", acc)
		const tokenmetaPubkey = await Metadata.getPDA(new web3.PublicKey(acc)); //Promise -> PublicKey (0x1231233434)


		// console.log("TokenMetaPubkey", tokenmetaPubkey.toString())
		return Metadata.load(this.connection, tokenmetaPubkey); // Promise -> Object(Metadata)
	}

	setCache(token, data) {
		try {
			data.hash = this.cacheHash
			localStorage.setItem(token, JSON.stringify(data));
		} catch (e) {
			//
		}
	}

	_cacheNFTAccounts(walletAddr, nfts) {
		try {
			localStorage.setItem(`nft_accounts:${walletAddr}`, JSON.stringify({
					items: nfts,
					hash: this.cacheHash,
					last_updated: Date.now()
				}
			));
		} catch (e) {
			//
		}
	}

	getCachedAccounts(walletAddr) {
		const data = localStorage.getItem(`nft_accounts:${walletAddr}`)
		if (data === null)
			return null;

		return JSON.parse(data)
	}

	/**
	 * Returns if data from local cache is valid
	 * @param data
	 * @returns {boolean}
	 */
	isLocalCacheValid(data) {
		if (data === null)
			return false;

		return data.hash === this.cacheHash
	}

	getCached(token) {
		const data = localStorage.getItem(token)
		if (data === null)
			return null;

		return JSON.parse(data);
	}
}
