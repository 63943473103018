import {api, apiHelper} from "./api";

export class Meeting {
	prefix() {
		return "meetings"
	}

	index(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?user=${wallet_addr}&${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	uploadScene(formData) {
		return api.post(`uploads/scene`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
	}

	/**
	 * Temp endpoint
	 * TODO move to WebRTC
	 * @param formData
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	uploadUserFile(formData) {
		return api.post(`/auth/users/file`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
	}

	end(uid) {
		return api.delete(`${this.prefix()}/${uid}`)
	}
}

export default new Meeting()
