import * as THREE from 'three'
import {GridExtension} from "./GridExtension";

export class SceneryManager extends GridExtension {
	models = {}
	entities = []

	instanceMap = {};
	instanceCount = {};

	//Buildings active on the scene
	activeEntities = {};

	perEntityCount = 300;

	buildingManager


	constructor(gridController, buildingManager, modelManager, animationManager, collisionHandler, interactionHandler, scene, entities = []) {
		super(gridController, modelManager, animationManager, collisionHandler, interactionHandler, scene)
		this.buildingManager = buildingManager
		this.models = entities; //Entities fed in from our manifest
	}

	onGridChange(x, z) {
		this.addScenery(this.perEntityCount / 5)
	}

	onRemoveEntity(uuid) {
		this.removeEntity(uuid);
	}

	onAddEntity(data) {
		this._addToTile(data)
	}

	//Called from main instance files
	load() {
		this.buildInstancedMap()
		this.addScenery(-1)
	}

	buildInstancedMap() {
		for (let i = 0; i < this.models.length; i++) {
			const entity = this.models[i].data
			console.log("Building instanced map for", entity)
			const m = this.modelManager.getModel(entity.model)
			if (!m) {
				console.warn("unable to get model", entity.model)
				continue
			}

			const imap = this._buildInstancedMap(m, this.perEntityCount)
			if (!imap) {
				console.warn("unable to build instanced mesh for model", entity.model)
				continue
			}
			console.log("Adding model map", entity.model, imap)
			this.instanceMap[entity.model] = imap
			this.instanceCount[entity.model] = 0
		}
	}

	_buildInstancedMap(model, count) {
		let ig = []
		let offsets = [];
		let rotationOffsets = [];

		const g = new THREE.Group()

		model.traverse(c => {
			if (!c.isMesh)
				return

			const imap = new THREE.InstancedMesh(c.geometry, c.material, count)
			imap.castShadow = false
			imap.receiveShadow = true
			offsets.push(c.position)
			rotationOffsets.push(c.quaternion)
			ig.push(imap)
			g.attach(imap)
		})

		if (ig.length === 0)
			return null

		this.scene.add(g)
		return {instances: ig, offsets: offsets, rotationOffsets: rotationOffsets}
	}

	getRandomSceneryKey() {
		return this.models[Math.floor(Math.random() * (this.models.length - 1))].data.model
	}

	addScenery(count = -1) {
		if (!this.gridController) return
		const tiles = this.tiles.getInsideOutEmptyTilesInRadius(this.x, this.z, 6, this.gridController.getDrawRadius(), count)
		console.log("Tilecount", tiles.length)
		for (let i = 0; i < tiles.length; i++) {
			const chanceToAdd = Math.random()
			if (chanceToAdd > 0.1)
				continue

			//City limits
			if ((tiles[i].x < 15 && tiles[i].x > -15) && (tiles[i].z < 15 && tiles[i].z > -15))
				continue

			if (this.buildingManager.getTile(tiles[i].x, tiles[i].z))
				continue; //Building placed

			// this._addToTile(tiles[i])
			this.loadingQueue.push(tiles[i])
		}
	}

	_addSceneryToScene(uuid, instanceKey, globalPos) {
		const scale = Math.random() + 0.5
		const entity = new THREE.Object3D();

		const im = this.instanceMap[instanceKey]

		for (let i = 0; i < im.instances.length; i++) {
			entity.position.set(globalPos.x, globalPos.y, globalPos.z)
			entity.position.add(im.offsets[i])
			entity.quaternion.copy(im.rotationOffsets[i])
			entity.updateMatrix()
			this.instanceMap[instanceKey].instances[i].setMatrixAt(uuid, entity.matrix.clone())
			this.instanceMap[instanceKey].instances[i].instanceMatrix.needsUpdate = true;
		}
	}

	_addToTile(tile) {
		const tileWidth = this.gridController.getTileWidth()
		const xOffset = (tile.x - (tile.x * 2)) * tileWidth
		const yOffset = tile.z * tileWidth
		let xTileOffset = Math.floor(Math.random() * tileWidth)
		let yTileOffset = Math.floor(Math.random() * tileWidth)
		const globalPos = new THREE.Vector3(tileWidth + xOffset + xTileOffset, 0, tileWidth + yOffset + yTileOffset)

		const sk = this.getRandomSceneryKey();
		this.instanceCount[sk]++
		if (this.instanceCount[sk] >= this.perEntityCount)
			this.instanceCount[sk] = 0;

		const uuid = this.instanceCount[sk];

		this._addSceneryToScene(uuid, sk, globalPos)

		this.setTile(tile.x, tile.z, tile)
		// this.activeEntities[entity.uuid] = entity
		this.entities.push({
			uuid: uuid,
			x: tile.x,
			z: tile.z,
			entity: uuid,
			userData: {tilesX: 1, tilesZ: 1}
		})
	}

	removeEntity(uuid) {
		this.scene.remove(uuid)

		const geom = this.activeEntities[uuid]
		if (!geom)
			return

		// console.debug("removeBuilding::Removing:", uuid, geom, this.buildingLoadQueue.length)
		this.scene.remove(geom)
		delete this.activeEntities[uuid]
	}

	reset() {
		super.reset();
		this.models = {}
		this.entities = []
		this.instanceMap = {};
		this.instanceCount = {};
		this.activeEntities = {};
	}
}
