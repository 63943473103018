export default class PortfolioCalculator {

    tokenCollection = {};
    collections = {};

    async calculateNFTWorth(ownedNFTs = []) {
        let total = 0;
        for (let i = 0; i < ownedNFTs.length; i++) {
            const nft = ownedNFTs[i];
            const price = await this.getNFTPrice(nft.id);
            total += price;
        }
        return total;
    }


    calculateNetWorth(nfts = [], collectionFloors = {}) {
        let total = 0;
        for (let i = 0; i < nfts.length; i++) {
            const nft = nfts[i];
            const price = collectionFloors[nft] ? collectionFloors[nft].floorPrice : 0;
            console.log(`${nft} - ${collectionFloors[nft].name} - Floor: ${collectionFloors[nft].floorPrice * 0.000000001}`)
            total += price;
        }

        //This is in lamports
        return total * 0.000000001;
    }

    async getNFTPrice(nftId) {
        return 0;
        // const tokenCollection = await this.getTokenCollection(nftId);
        // const collection = this.getCollection(tokenCollection)
        // return collection.floorPrice;
    }

    // async getTokenCollection(nftId) {
    //     if (this.tokenCollection[nftId])
    //         return this.tokenCollection[nftId];
    //
    //     const nftData = await axios.get(`https://api-mainnet.magiceden.dev/v2/tokens/${nftId}`)
    //     this.tokenCollection[nftId] = nftData.data.collection;
    //
    //     return this.tokenCollection[nftId]
    // }

    /**
     *
     * @param collection must be magic-eden slug
     * @returns {Promise<*>}
     */
    // async getCollection(collection) {
    //     if (this.collections[collection]) {
    //         return this.collections[collection];
    //     }
    //
    //     const resp = axios.get(`https://api-mainnet.magiceden.dev/v2/collections/${collection}`);
    //     this.collections[collection] = resp.data;
    //     return resp.data
    // }
}