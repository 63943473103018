import {ManifestController} from "./manifest_controller";

export class ModelController extends ManifestController {

    add(scene, data, folder = null, callback = () => {}, addToScene = true) {
        // console.log("ModelController.add()", scene, data);
        if (data.scene === ''){
            callback()
            return
        }

        this.modelManager.addModel(data.id, data.scene, (obj) => {
            // console.log("Model added:", data.id)
            callback();
        })

        callback()
    }

    toManifest() {
        return {}
    }
}