import {api, apiHelper} from "./api";

export class Land {
	prefix() {
		return "land"
	}

	index(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexSector(x, y, size = 100, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/${x}/${y}/sector/${size}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexRadius(x, y, size = 100, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/${x}/${y}/radius/${size}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(x,y) {
		return api.get(`${this.prefix()}/${x}/${y}`)
	}

	build(x, y, obj) {
		return api.post(`${this.prefix()}/${x}/${y}/build`, obj)
	}

	showBuilding(x,y) {
		return api.get(`${this.prefix()}/${x}/${y}/building`)
	}

	update(uid, obj) {
		return api.put(`${this.prefix()}/${uid}`, obj)
	}

	delete(uid) {
		return api.delete(`${this.prefix()}/${uid}`)
	}
}

export default new Land()