import {api} from "./api";
import axios from "axios";

export class Portfolio {
    baseURL = "https://market-bot.alphabatem.com"

    prefix() {
        return "/portfolio"
    }

    token(marketplace, tokenAddr) {
        return api.get(`${this.baseURL}${this.prefix()}/${marketplace}/tokens/${tokenAddr}`)
    }

    tokens(marketplace, tokens = []) {
        return api.post(`${this.baseURL}${this.prefix()}/${marketplace}/tokens`, {tokens: tokens})
    }

    collection(marketplace, collection) {
        return api.get(`${this.baseURL}${this.prefix()}/${marketplace}/collections/${collection}`)
    }

    tokenCollections(marketplace, tokens = []) {
        return api.post(`${this.baseURL}${this.prefix()}/${marketplace}/token-collections`, {tokens: tokens})
    }

    collections(marketplace, collections = []) {
        return api.post(`${this.baseURL}${this.prefix()}/${marketplace}/collections`, {collections: collections})
    }

    getSolPrice() {
        return axios.get("https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd")
    }
}

export default new Portfolio()