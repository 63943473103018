import {ManifestController} from "./manifest_controller";

export class AnimationsController extends ManifestController {
    animations = {};

    add(animationName, data, folder = null, callback = () => {
    }, addToScene = true) {
        return

        this.modelManager.addModel(data.id, data.scene, (obj) => {
            const model = this.modelManager.getLoadedModel(data.id);

            console.log("AnimationsController.add", model)

            const anim = model.animations[0];
            anim.name = animationName;

            this.animations[animationName] = anim
        })
    }

    getAnimation(animName) {
        return this.animations[animName]
    }

    defaultAnimations() {
        return this.animations
    }

    defaultAnimationMap() {
        return {
            "idle": "idle-loop",
            "walk": "walk-loop",
        }
    }

    toManifest(obj) {
        return obj;
    }
}