import * as THREE from 'three'
import {ManifestController} from "../manifest_controller";

import {VoxelTypes} from "./voxel_types"
import {recursiveUpdate} from "../../recursiveUpdate";

export class VoxelBuilderController extends ManifestController {

	metaverse;
	scene;
	cubeSize = 2

	voxelType = 0

	mouse = {x: 0, y: 0};
	raycaster = new THREE.Raycaster()

	lastGridPos = new THREE.Vector3(0,0,0)
	lastVoxelNormal = new THREE.Vector3(0,0,0)

	onVoxelDelete = (voxel) => {
	}

	constructor(voxelManager, metaverse, scene) {
		super();
		this.voxelsManager = voxelManager
		this.voxelsManager.cubeSize = this.cubeSize
		this.metaverse = metaverse
		this.scene = scene
		this.previewVoxel = this.voxelsManager.createVoxel(VoxelTypes.GRASS)
		for (let i = 0; i < this.previewVoxel.children.length; i++) {
			this.previewVoxel.children[i].userData.isTerrain = false
			this.previewVoxel.children[i].name = 'previewVoxel'
		}
		this.previewVoxel.visible = false
		this.nextCubeToBePlaced = this.previewVoxel.clone()
		this.nextCubeToBePlaced.visible = false
	}

	add(scene, data, folder = null, callback = () => {
	}, addToScene = true) {

		console.log("voxel.add()", data);

		let terrainObj = data.model;

		console.log("voxel_builder_controller::Adding to scene", terrainObj)

		terrainObj.name = `terrain_${data.id}`
		terrainObj.castShadow = true
		terrainObj.receiveShadow = true
		terrainObj.userData.isTerrain = true
		terrainObj.userData.alphaID = data.id
		terrainObj.userData.name = data.name
		terrainObj.position.set(data.position.x, data.position.y, data.position.z)
		terrainObj.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z)
		terrainObj.scale.set(data.scale.x, data.scale.y, data.scale.z)

		if (addToScene) {
			console.log("Adding terrain object to scene: ", terrainObj)
			recursiveUpdate(terrainObj)
			scene.add(terrainObj);
		}

		callback();
	}

	toManifest(obj) {
		return {
			id: obj.userData.alphaID,
			name: obj.userData.name || obj.userData.alphaID,
			position: {
				x: obj.position.x,
				y: obj.position.y,
				z: obj.position.z
			},
			rotation: {
				x: obj.rotation.x,
				y: obj.rotation.y,
				z: obj.rotation.z
			},
			scale: {
				x: obj.scale.x,
				y: obj.scale.y,
				z: obj.scale.z
			}
		}
	}

	raycastToGrid(raycast) {
		let normal
		if (raycast.object.type === "GridHelper") {
			normal = raycast.object.position
		} else {
			normal = raycast.face.normal
		}

		return this._toGridPosition(raycast.point, normal)
	}

	_toGridPosition(position, normal) {
		const {x, y, z} = position
		const gridX = (normal.x > 0 ? Math.floor(x / this.cubeSize) : Math.ceil(x / this.cubeSize)) * this.cubeSize
		const gridY = (normal.y > 0 ? Math.floor(y / this.cubeSize) : Math.round(y / this.cubeSize)) * this.cubeSize
		const gridZ = (normal.z > 0 ? Math.floor(z / this.cubeSize) : Math.ceil(z / this.cubeSize)) * this.cubeSize

		return {
			x: gridX + (normal.x * this.cubeSize),
			y: gridY + (normal.y * this.cubeSize),
			z: gridZ + (normal.z * this.cubeSize),
		}
	}

	setupListeners() {
		this.previewVoxel.visible = false
		window.addEventListener('click', this.placeVoxel)
		window.addEventListener('mousemove', this.movePreviewVoxel)
	}

	removeListeners() {
		this.previewVoxel.visible = false

		window.removeEventListener('click', this.placeVoxel)
		window.removeEventListener('mousemove', this.movePreviewVoxel)
	}

	selectVoxelType(voxelType) {
		this.voxelType = voxelType

		this.scene.remove(this.previewVoxel)

		this.previewVoxel = this.voxelsManager.createVoxel(voxelType)
		console.log('selectVoxelType::previewVoxel', this.previewVoxel)

		for (let i = 0; i < this.previewVoxel.children.length; i++) {
			this.previewVoxel.children[i].userData.isTerrain = false
			this.previewVoxel.children[i].name = 'previewVoxel'
		}
		this.nextCubeToBePlaced = this.previewVoxel.clone()

		this.previewVoxel.visible = false
		this.scene.add(this.previewVoxel)
	}

	placeVoxel = (e) => {
		if (this.metaverse.deactivateListeners) return

		if (!this.previewVoxel.visible) {
			console.log("Skipping placement")
			return;
		}

		const obj = this.metaverse.editor._raycast({
			clientX: window.innerWidth / 2,
			clientY: window.innerHeight / 2,
		})

		if (!obj) return

		if (e.button === 0 && e.shiftKey) {
			this.scene.remove(obj.parent)
			console.log('voxel_builder_controller::delete', obj)
			this.onVoxelDelete(obj.parent)
			return
		}

		if (e.button == 0) {
			this.nextCubeToBePlaced.position.copy(this.lastGridPos)
			this.nextCubeToBePlaced.name = "voxel"
			for (let i = 0; i < this.nextCubeToBePlaced.children.length; i++) {
				this.nextCubeToBePlaced.children[i].name = 'voxel'
			}

			// instead of the this.editor.onManifestAdd()
			const retVoxel = this.metaverse.editor.addVoxel(this.nextCubeToBePlaced, this.voxelType)
			this.metaverse.onUpdate("voxel_builder", retVoxel)
			this.nextCubeToBePlaced = this.previewVoxel.clone()

			if (this.lastVoxelNormal === null){
				this.previewVoxel.visible = false
			} else {
				const {x,y,z} = this._toGridPosition(this.lastGridPos, this.lastVoxelNormal) //Use last normal to increment placement position in the direction of camera
				this.previewVoxel.position.set(x,y,z)
				this.lastGridPos.set(x,y,z) //For next time
			}
		}
	}

	/**
	 * Returns if the given object should be filtered out of the array or not
	 * @param r
	 * @returns {boolean}
	 * @private
	 */
	_voxelFilter(r) {

		if (r.object.name === "previewVoxel")
			return true

		if (r.object.type === "GridHelper")
			return true

		return false
	}

	movePreviewVoxel = (e) => {
		const raycaster = this.metaverse.editor._filteredRaycast({
			clientX: window.innerWidth / 2,
			clientY: window.innerHeight / 2
		}, this._voxelFilter)

		if (!raycaster)  {
			this.previewVoxel.visible = false
			return
		}

		this.previewVoxel.visible = true
		let {x, y, z} = this.raycastToGrid(raycaster)

		if (raycaster.object.name !== "voxel")
			y = 0

		this.previewVoxel.position.set(x, y, z)

		if (this.lastGridPos.equals(this.previewVoxel.position))
			return;


		if (raycaster.object.name !== "voxel")
			this.lastVoxelNormal = null
		else
			this.lastVoxelNormal = raycaster.face.normal


		this.lastGridPos.copy(this.previewVoxel.position)
		console.log("RC", raycaster.object.name, raycaster, this.lastGridPos)
	}
}
